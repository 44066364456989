import { Shared_UniversalArgs } from "../ReportPdf/Sections/SharedSection.js";
import { Args } from "../ReportPdf/Sections/StudyInfoSection.js";
import { map } from "../../../fable_modules/fable-library.3.7.20/Option.js";
import { Args as Args_1 } from "../ReportPdf/Sections/MeasurementSection.js";
import { Args as Args_2 } from "../ReportPdf/Sections/AdditionalDataSection.js";
import { Args as Args_3 } from "../ReportPdf/Sections/ScreenshotSection.js";
import { Args as Args_4 } from "../ReportPdf/Sections/ConclusionSection.js";
import { FullReportArgs } from "../ReportPdf/Sections/MakeReportSections.js";

export function makeReportArgs(args, model) {
    return new FullReportArgs(new Shared_UniversalArgs(args.fileArgs.Token, args.fileArgs.Context), new Args(model.SelectedStudy, model.MaybePreviousStudy), new Args_1(model.SelectedStudy.studyId, map((study) => study.studyId, model.MaybePreviousStudy), model.MaybeCurrentStudyAcquisitionDate, model.MaybePreviousStudyAcquisitionDate), new Args_2(model.GeometryData, void 0, void 0), new Args_2(model.GrowthData, model.MaybeCurrentStudyAcquisitionDate, model.MaybePreviousStudyAcquisitionDate), new Args_3(model.SelectedStudy.studyId, map((study_1) => study_1.studyId, model.MaybePreviousStudy)), new Args_4(args.customReport));
}

