import { Union, Record } from "./fable_modules/fable-library.3.7.20/Types.js";
import { option_type, bool_type, class_type, union_type, record_type, string_type } from "./fable_modules/fable-library.3.7.20/Reflection.js";
import { parse } from "./fable_modules/fable-library.3.7.20/Int32.js";
import { substring } from "./fable_modules/fable-library.3.7.20/String.js";
import style from "../../src/RAWMap.Client/style.scss";
import { FSharpResult$2, FSharpChoice$2 } from "./fable_modules/fable-library.3.7.20/Choice.js";
import { Urls_Page$reflection } from "./Common/General.js";
import { Model$reflection as Model$reflection_2, Msg$reflection as Msg$reflection_1 } from "./Auth/AuthTypes.js";
import { S3Configuration$reflection, DeveloperIdentity$reflection } from "./RAWMap.Models/Api.js";
import { ErrorMessage$reflection } from "./RAWMap.Models/ErrorMessage.js";
import { S3Context$reflection } from "./Common/AwsCommon.js";
import { Model$reflection as Model$reflection_3, Msg$reflection as Msg$reflection_2 } from "./Institution/InstitutionTypes.js";
import { Model$reflection as Model$reflection_4, Msg$reflection as Msg$reflection_3 } from "./User/UserTypes.js";
import { Model$reflection as Model$reflection_5, Msg$reflection as Msg$reflection_4 } from "./Study/StudyTypes.js";
import { Model$reflection as Model$reflection_6, Msg$reflection as Msg$reflection_5 } from "./FileManagement/FileUploadParentExample/FileParentTypes.js";
import { Model$reflection as Model$reflection_1, Msg$reflection as Msg$reflection_6 } from "./Notifications/Types.js";
import { Model$reflection as Model$reflection_7, Msg$reflection as Msg$reflection_7 } from "./Patient/PatientTypes.js";
import { Model$reflection as Model$reflection_8, Msg$reflection as Msg$reflection_8 } from "./SystemInformation/SystemInformationTypes.js";
import { ofArray, forAll } from "./fable_modules/fable-library.3.7.20/List.js";
import { analysisIsBeingModified, coreStudyIsBeingDownloaded, coreIsBeingModified } from "./Study/StudyView.js";
import { equals } from "./fable_modules/fable-library.3.7.20/Util.js";
import { value as value_2 } from "./fable_modules/fable-library.3.7.20/Option.js";
import { DeidentificationState } from "./Study/CoreForm/CoreFormTypes.js";

export class ScssVariables extends Record {
    constructor(tabletBreak, desktopBreak, widescreenBreak, fullHdBreak) {
        super();
        this.tabletBreak = tabletBreak;
        this.desktopBreak = desktopBreak;
        this.widescreenBreak = widescreenBreak;
        this.fullHdBreak = fullHdBreak;
    }
}

export function ScssVariables$reflection() {
    return record_type("Client.Types.ScssVariables", [], ScssVariables, () => [["tabletBreak", string_type], ["desktopBreak", string_type], ["widescreenBreak", string_type], ["fullHdBreak", string_type]]);
}

export class ScreenWidths_ScreenWidth extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Mobile", "Tablet", "Desktop", "Widescreen", "FullHd"];
    }
}

export function ScreenWidths_ScreenWidth$reflection() {
    return union_type("Client.Types.ScreenWidths.ScreenWidth", [], ScreenWidths_ScreenWidth, () => [[], [], [], [], []]);
}

export function ScreenWidths_trimPx(width) {
    return parse(substring(width, 0, width.length - 2), 511, false, 32);
}

export function ScreenWidths_getClientWidth() {
    return window.document.body.clientWidth;
}

export const ScreenWidths_tabletWidth = ScreenWidths_trimPx(style.tabletBreak);

export const ScreenWidths_desktopWidth = ScreenWidths_trimPx(style.desktopBreak);

export const ScreenWidths_widescreenWidth = ScreenWidths_trimPx(style.widescreenBreak);

export const ScreenWidths_fullHdWidth = ScreenWidths_trimPx(style.fullHdBreak);

export function ScreenWidths_getScreenWidth() {
    const screenWidth = ScreenWidths_getClientWidth();
    if (screenWidth < ScreenWidths_tabletWidth) {
        return new ScreenWidths_ScreenWidth(0);
    }
    else if (screenWidth < ScreenWidths_desktopWidth) {
        return new ScreenWidths_ScreenWidth(1);
    }
    else if (screenWidth < ScreenWidths_widescreenWidth) {
        return new ScreenWidths_ScreenWidth(2);
    }
    else if (screenWidth < ScreenWidths_fullHdWidth) {
        return new ScreenWidths_ScreenWidth(3);
    }
    else {
        return new ScreenWidths_ScreenWidth(4);
    }
}

export class Sidebar_CollapseState extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Expanded", "Collapsed"];
    }
}

export function Sidebar_CollapseState$reflection() {
    return union_type("Client.Types.Sidebar.CollapseState", [], Sidebar_CollapseState, () => [[], []]);
}

export class Sidebar_ModeSidebar extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AlwaysVisible", "Collapsible"];
    }
}

export function Sidebar_ModeSidebar$reflection() {
    return union_type("Client.Types.Sidebar.ModeSidebar", [], Sidebar_ModeSidebar, () => [[], [["Item", Sidebar_CollapseState$reflection()]]]);
}

export function Sidebar_$007CShowSidebar$007CHideSidebar$007C(mode) {
    let pattern_matching_result;
    if (mode.tag === 1) {
        if (mode.fields[0].tag === 1) {
            pattern_matching_result = 1;
        }
        else {
            pattern_matching_result = 0;
        }
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            return new FSharpChoice$2(0, void 0);
        }
        case 1: {
            return new FSharpChoice$2(1, void 0);
        }
    }
}

export function Sidebar_$007CShowBurger$007CHideBurger$007C(mode) {
    if (mode.tag === 1) {
        return new FSharpChoice$2(0, void 0);
    }
    else {
        return new FSharpChoice$2(1, void 0);
    }
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "Navigate", "AuthMsg", "CreateIdentityResult", "SetCredentialExpiration", "InstitutionMsg", "UserMsg", "StudyMsg", "FileManagementMsg", "NotificationsMsg", "GetS3Configuration", "GotS3Configuration", "PatientMsg", "SystemInformationMsg", "MenuToggled"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [["Item", Urls_Page$reflection()]], [["Item", Msg$reflection_1()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [DeveloperIdentity$reflection(), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", DeveloperIdentity$reflection()]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item1", class_type("Fable.Import.Aws.CognitoCredentials.CognitoIdentityCredentials")], ["Item2", S3Context$reflection()]], [["Item", Msg$reflection_2()]], [["Item", Msg$reflection_3()]], [["Item", Msg$reflection_4()]], [["Item", Msg$reflection_5()]], [["Item", Msg$reflection_6()]], [], [["Item", S3Configuration$reflection()]], [["Item", Msg$reflection_7()]], [["Item", Msg$reflection_8()]], [["Item", bool_type]]]);
}

export class Model extends Record {
    constructor(NotificationsModel, CurrentPage, AuthModel, InstitutionsModel, UsersModel, StudyModel, FileManagementModel, S3Context, AwsToken, AwsConfig, Credentials, PatientsModel, SystemInformationModel, MenuIsExpanded, RedirectPage, IsDebug) {
        super();
        this.NotificationsModel = NotificationsModel;
        this.CurrentPage = CurrentPage;
        this.AuthModel = AuthModel;
        this.InstitutionsModel = InstitutionsModel;
        this.UsersModel = UsersModel;
        this.StudyModel = StudyModel;
        this.FileManagementModel = FileManagementModel;
        this.S3Context = S3Context;
        this.AwsToken = AwsToken;
        this.AwsConfig = AwsConfig;
        this.Credentials = Credentials;
        this.PatientsModel = PatientsModel;
        this.SystemInformationModel = SystemInformationModel;
        this.MenuIsExpanded = MenuIsExpanded;
        this.RedirectPage = RedirectPage;
        this.IsDebug = IsDebug;
    }
}

export function Model$reflection() {
    return record_type("Client.Types.Model", [], Model, () => [["NotificationsModel", Model$reflection_1()], ["CurrentPage", Urls_Page$reflection()], ["AuthModel", Model$reflection_2()], ["InstitutionsModel", Model$reflection_3()], ["UsersModel", Model$reflection_4()], ["StudyModel", Model$reflection_5()], ["FileManagementModel", Model$reflection_6()], ["S3Context", option_type(S3Context$reflection())], ["AwsToken", option_type(DeveloperIdentity$reflection())], ["AwsConfig", option_type(S3Configuration$reflection())], ["Credentials", option_type(class_type("Fable.Import.Aws.Credentials.Credentials"))], ["PatientsModel", Model$reflection_7()], ["SystemInformationModel", Model$reflection_8()], ["MenuIsExpanded", bool_type], ["RedirectPage", option_type(Urls_Page$reflection())], ["IsDebug", bool_type]]);
}

export function AppState_canNavigate(model) {
    return forAll((value) => (!value), ofArray([coreIsBeingModified(model.StudyModel), coreStudyIsBeingDownloaded(model.StudyModel), analysisIsBeingModified(model.StudyModel)]));
}

export function AppState_canStayLoggedIn(model) {
    const matchValue = model.StudyModel.CoreFormComponent;
    let pattern_matching_result, cfc;
    if (matchValue != null) {
        if (matchValue.Mode.tag === 1) {
            pattern_matching_result = 0;
            cfc = matchValue;
        }
        else if (matchValue.Mode.tag === 2) {
            pattern_matching_result = 0;
            cfc = matchValue;
        }
        else if (matchValue.Mode.tag === 4) {
            pattern_matching_result = 1;
        }
        else {
            pattern_matching_result = 2;
        }
    }
    else {
        pattern_matching_result = 2;
    }
    switch (pattern_matching_result) {
        case 0: {
            if (equals(value_2(cfc).DeidentificationState, new DeidentificationState(1))) {
                return true;
            }
            else {
                return equals(value_2(cfc).DeidentificationState, new DeidentificationState(2));
            }
        }
        case 1: {
            return true;
        }
        case 2: {
            return false;
        }
    }
}

export function AppState_sidebarMode(model) {
    const matchValue = [ScreenWidths_getScreenWidth(), model.StudyModel.VisualizationComponent, model.CurrentPage];
    let pattern_matching_result;
    if (matchValue[0].tag === 0) {
        pattern_matching_result = 0;
    }
    else if (matchValue[0].tag === 1) {
        pattern_matching_result = 0;
    }
    else if (matchValue[1] != null) {
        if (matchValue[2].tag === 6) {
            if (matchValue[2].fields[0].tag === 2) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return new Sidebar_ModeSidebar(1, model.MenuIsExpanded ? (new Sidebar_CollapseState(0)) : (new Sidebar_CollapseState(1)));
        }
        case 1: {
            return new Sidebar_ModeSidebar(0);
        }
    }
}

export const AppState_canLogout = (arg) => (!AppState_canStayLoggedIn(arg));

