import { Urls_PatientPage, Urls_Page, Controls_containerWithContent } from "./Common/General.js";
import { view } from "./Auth/LoginView.js";
import { Sidebar_$007CShowBurger$007CHideBurger$007C, AppState_sidebarMode, Msg } from "./Types.js";
import { viewRequestResetEmail, view as view_1 } from "./Auth/ResetPasswordView.js";
import { view as view_2 } from "./Auth/RegisterView.js";
import { view as view_3 } from "./Institution/InstitutionView.js";
import { view as view_4 } from "./User/UserView.js";
import { map, bind, defaultArg, value as value_5 } from "./fable_modules/fable-library.3.7.20/Option.js";
import * as react from "react";
import { keyValueList } from "./fable_modules/fable-library.3.7.20/MapUtil.js";
import { view as view_5 } from "./Study/StudyView.js";
import { view as view_6 } from "./FileManagement/FileUploadParentExample/FileParentView.js";
import { view as view_7 } from "./Patient/PatientView.js";
import { view as view_8 } from "./SystemInformation/SystemInformationView.js";
import { collect, ofArray, map as map_1, singleton, empty, tryExactlyOne, tryFind } from "./fable_modules/fable-library.3.7.20/List.js";
import { Option as Option_3, Burger_Option, burger, Item_div, End_div, menu as menu_1, Item_Option, Item_a, Brand_div, navbar as navbar_1 } from "./fable_modules/Fulma.2.16.0/Components/Navbar.fs.js";
import { Common_EmailAddress_value_Z39D6D3C1 } from "./fable_modules/Webbler.Models.1.2.2/Api.fs.js";
import { isNullOrEmpty, printf, toText, join } from "./fable_modules/fable-library.3.7.20/String.js";
import { UserRole_get_describe } from "./RAWMap.Models/Security.js";
import { Common_GenericOption, Modifier_IModifier, Screen } from "./fable_modules/Fulma.2.16.0/Common.fs.js";
import { right, Item_Option as Item_Option_1, item, Level_Option, level } from "./fable_modules/Fulma.2.16.0/Layouts/Level.fs.js";
import { sessionExpiryModal, view as view_9 } from "./Auth/AuthView.js";
import { canViewPage } from "./Routing.js";
import { panel as panel_1, icon as icon_1, Block_Option, Block_a } from "./fable_modules/Fulma.2.16.0/Components/Panel.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.7.20/Seq.js";
import { HTMLAttr, DOMAttr } from "./fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { equals } from "./fable_modules/fable-library.3.7.20/Util.js";
import { Option, icon as icon_2 } from "./fable_modules/Fulma.2.16.0/Elements/Icon.fs.js";
import { Fa_IconOption, Fa_i } from "./fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { Option as Option_1, ISize, column } from "./fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { content as content_15 } from "./fable_modules/Fulma.2.16.0/Elements/Content.fs.js";
import { Helpers_nothing } from "./fable_modules/Fable.React.7.4.3/Fable.React.Helpers.fs.js";
import { Option as Option_2, columns } from "./fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { year, now } from "./fable_modules/fable-library.3.7.20/Date.js";

export function root(model, dispatch) {
    let props_6, children_6, props_8, props_10, children_10, arg_32, copyOfStruct, children_28;
    let content_13;
    const matchValue = model.CurrentPage;
    let pattern_matching_result, pid, _sid, pid_1;
    if (matchValue.tag === 2) {
        pattern_matching_result = 4;
    }
    else if (matchValue.tag === 3) {
        if (model.AuthModel.userInfo != null) {
            pattern_matching_result = 5;
        }
        else {
            pattern_matching_result = 6;
        }
    }
    else if (matchValue.tag === 4) {
        pattern_matching_result = 7;
    }
    else if (matchValue.tag === 5) {
        pattern_matching_result = 8;
    }
    else if (matchValue.tag === 1) {
        pattern_matching_result = 9;
    }
    else if (matchValue.tag === 6) {
        if (matchValue.fields[0].tag === 2) {
            if (matchValue.fields[0].fields[0][1] != null) {
                pattern_matching_result = 13;
                _sid = matchValue.fields[0].fields[0][1];
                pid_1 = matchValue.fields[0].fields[0][0];
            }
            else {
                pattern_matching_result = 11;
                pid = matchValue.fields[0].fields[0][0];
            }
        }
        else if (matchValue.fields[0].tag === 1) {
            if (matchValue.fields[0].fields[0] == null) {
                pattern_matching_result = 12;
            }
            else {
                pattern_matching_result = 11;
                pid = matchValue.fields[0].fields[0];
            }
        }
        else {
            pattern_matching_result = 10;
        }
    }
    else if (matchValue.tag === 7) {
        pattern_matching_result = 14;
    }
    else if (matchValue.fields[0].tag === 1) {
        pattern_matching_result = 1;
    }
    else if (matchValue.fields[0].tag === 2) {
        pattern_matching_result = 2;
    }
    else if (matchValue.fields[0].tag === 3) {
        pattern_matching_result = 3;
    }
    else {
        pattern_matching_result = 0;
    }
    switch (pattern_matching_result) {
        case 0: {
            content_13 = Controls_containerWithContent("Login", view(model.AuthModel, (arg_1) => {
                dispatch(new Msg(2, arg_1));
            }));
            break;
        }
        case 1: {
            content_13 = Controls_containerWithContent("Reset Password", view_1(model.AuthModel.resetPasswordModel, (arg_3) => {
                dispatch(new Msg(2, arg_3));
            }));
            break;
        }
        case 2: {
            content_13 = Controls_containerWithContent("Reset Password", viewRequestResetEmail(model.AuthModel.resetPasswordModel.resetRequestEmail, (arg_5) => {
                dispatch(new Msg(2, arg_5));
            }));
            break;
        }
        case 3: {
            content_13 = Controls_containerWithContent("Register", view_2(model.AuthModel, (arg_7) => {
                dispatch(new Msg(2, arg_7));
            }));
            break;
        }
        case 4: {
            content_13 = Controls_containerWithContent("Institutions", view_3(model.InstitutionsModel, (arg_9) => {
                dispatch(new Msg(5, arg_9));
            }));
            break;
        }
        case 5: {
            content_13 = Controls_containerWithContent("Users", view_4(model.UsersModel, value_5(model.AuthModel.userInfo), (arg_11) => {
                dispatch(new Msg(6, arg_11));
            }));
            break;
        }
        case 6: {
            const props = [["style", {
                textAlign: "center",
                padding: 20,
            }]];
            content_13 = react.createElement("div", keyValueList(props, 1), "You are not logged in");
            break;
        }
        case 7: {
            content_13 = Controls_containerWithContent("Study", view_5("00000000-0000-0000-0000-000000000000", model.StudyModel, (arg_13) => {
                dispatch(new Msg(7, arg_13));
            }));
            break;
        }
        case 8: {
            const children_2 = [Controls_containerWithContent("FileManagement", view_6(model.FileManagementModel, (arg_15) => {
                dispatch(new Msg(8, arg_15));
            }))];
            content_13 = react.createElement("div", {}, ...children_2);
            break;
        }
        case 9: {
            const props_4 = [["style", {
                textAlign: "center",
                padding: 20,
            }]];
            content_13 = react.createElement("div", keyValueList(props_4, 1), "Welcome home");
            break;
        }
        case 10: {
            content_13 = Controls_containerWithContent("Patients", view_7(model.PatientsModel, (arg_17) => {
                dispatch(new Msg(12, arg_17));
            }));
            break;
        }
        case 11: {
            content_13 = Controls_containerWithContent("Study", view_5(pid, model.StudyModel, (arg_19) => {
                dispatch(new Msg(7, arg_19));
            }));
            break;
        }
        case 12: {
            content_13 = Controls_containerWithContent("Patients", view_7(model.PatientsModel, (arg_21) => {
                dispatch(new Msg(12, arg_21));
            }));
            break;
        }
        case 13: {
            content_13 = Controls_containerWithContent("Study", view_5(pid_1, model.StudyModel, (arg_23) => {
                dispatch(new Msg(7, arg_23));
            }));
            break;
        }
        case 14: {
            content_13 = Controls_containerWithContent("System Information", view_8(model.SystemInformationModel, (arg_25) => {
                dispatch(new Msg(13, arg_25));
            }));
            break;
        }
    }
    const showInstitution = defaultArg(bind((ui) => bind((iid) => map((i_1) => i_1.name, tryFind((i) => (i.id === iid), model.InstitutionsModel.Institutions)), tryExactlyOne(ui.userData.institutions)), model.AuthModel.userInfo), "");
    const maybeActivePatientInstDesignation = bind((patient) => {
        const _arg_1 = tryFind((i_2) => (i_2.id === patient.institutionId), model.UsersModel.institutions);
        if (_arg_1 == null) {
            return void 0;
        }
        else {
            return _arg_1.clinicalDesignation;
        }
    }, model.PatientsModel.SelectedPatient);
    let navbar;
    const matchValue_1 = model.AuthModel.userInfo;
    if (matchValue_1 == null) {
        navbar = navbar_1(empty(), singleton(Brand_div(empty(), singleton(Item_a(empty(), singleton(react.createElement("img", {
            src: "logo.png",
            height: 150,
        })))))));
    }
    else {
        const ui_1 = matchValue_1;
        let describeUser;
        const children_12 = [(props_6 = [["style", {
            marginRight: 10,
        }]], (children_6 = [Common_EmailAddress_value_Z39D6D3C1(ui_1.email)], react.createElement("span", keyValueList(props_6, 1), ...children_6))), (props_8 = [["style", {
            marginRight: 10,
        }]], react.createElement("span", keyValueList(props_8, 1), showInstitution)), (props_10 = [["style", {
            color: "#BBBBBB",
        }]], (children_10 = [join(",", map_1(UserRole_get_describe(), ui_1.roles))], react.createElement("span", keyValueList(props_10, 1), ...children_10)))];
        describeUser = react.createElement("div", {}, ...children_12);
        navbar = navbar_1(empty(), ofArray([Brand_div(empty(), ofArray([Item_a(empty(), singleton(react.createElement("img", {
            src: "logo.png",
            height: 150,
        }))), Item_a(ofArray([new Item_Option(7, singleton(new Modifier_IModifier(24, new Screen(1), true))), new Item_Option(5, singleton(["style", {
            flexGrow: 1,
        }]))]), singleton(level(ofArray([new Level_Option(0, singleton(["style", {
            flexGrow: 1,
        }])), new Level_Option(1)]), ofArray([item(singleton(new Item_Option_1(0, singleton(["style", {
            flexGrow: 1,
        }]))), empty()), right(empty(), singleton(item(empty(), singleton(view_9(model.AuthModel, (arg_27) => {
            dispatch(new Msg(2, arg_27));
        })))))]))))])), menu_1(empty(), singleton(End_div(empty(), ofArray([defaultArg(map((_ui) => Item_div(empty(), singleton(describeUser)), model.AuthModel.userInfo), react.createElement("div", {})), Item_div(empty(), singleton(view_9(model.AuthModel, (arg_29) => {
            dispatch(new Msg(2, arg_29));
        })))]))))]));
    }
    let menu;
    if (model.AuthModel.userInfo == null) {
        menu = react.createElement("div", {});
    }
    else {
        const pages = collect((page) => {
            let linkedPage;
            if (canViewPage(page.Page, model)) {
                return singleton((linkedPage = page.Page, Block_a(toList(delay(() => append(singleton_1(new Block_Option(1, ofArray([new DOMAttr(40, (_arg) => {
                    dispatch(new Msg(1, linkedPage));
                }), ["style", {
                    paddingRight: "2rem",
                }]]))), delay(() => (equals(linkedPage, model.CurrentPage) ? singleton_1(new Block_Option(0, true)) : empty_1()))))), ofArray([icon_1(empty(), singleton(icon_2(singleton(new Option(4, singleton(["style", {
                    justifyContent: "revert",
                    alignItems: "baseline",
                }]))), singleton(Fa_i(singleton(page.Icon), []))))), page.Label]))));
            }
            else {
                return empty();
            }
        }, ofArray([{
            Icon: new Fa_IconOption(11, "fas fa-home"),
            Label: "Dashboard",
            Page: new Urls_Page(1),
        }, {
            Icon: new Fa_IconOption(11, "fas fa-users"),
            Label: "Users",
            Page: new Urls_Page(3),
        }, {
            Icon: new Fa_IconOption(11, "fas fa-hospital"),
            Label: "Institutions",
            Page: new Urls_Page(2),
        }, {
            Icon: new Fa_IconOption(11, "fas fa-users"),
            Label: "Patients",
            Page: new Urls_Page(6, new Urls_PatientPage(0)),
        }, {
            Icon: new Fa_IconOption(11, "fas fa-file-alt"),
            Label: "FileManagement",
            Page: new Urls_Page(5),
        }, {
            Icon: new Fa_IconOption(11, "fas fa-info-circle"),
            Label: "System Information",
            Page: new Urls_Page(7),
        }]));
        const panel = (content_14) => panel_1(empty(), toList(delay(() => content_14)));
        const sidebarMode = AppState_sidebarMode(model);
        let hamburger;
        const children_22 = [burger(ofArray([new Burger_Option(0, model.MenuIsExpanded), new Burger_Option(2, ofArray([new DOMAttr(40, (_e) => {
            dispatch(new Msg(14, !model.MenuIsExpanded));
        }), new HTMLAttr(135, "button"), ["style", {
            marginLeft: "0px",
            display: "block",
        }]]))]), ofArray([react.createElement("span", {}), react.createElement("span", {}), react.createElement("span", {})]))];
        hamburger = react.createElement("div", {}, ...children_22);
        menu = column(ofArray([new Option_1(0, new Screen(0), new ISize(17)), new Option_1(3, ofArray([new HTMLAttr(99, "sidebar-nav"), ["style", {
            backgroundColor: "#FFFFFF",
        }]]))]), singleton(content_15(empty(), toList(delay(() => append((Sidebar_$007CShowBurger$007CHideBurger$007C(sidebarMode).tag === 1) ? singleton_1(Helpers_nothing) : singleton_1(hamburger), delay(() => {
            let props_28, children_24;
            const matchValue_4 = sidebarMode;
            return (matchValue_4.tag === 1) ? ((matchValue_4.fields[0].tag === 0) ? singleton_1((props_28 = [["style", {
                position: "absolute",
                backgroundColor: "#FFFFFF",
                zIndex: 2,
            }]], (children_24 = [panel(pages)], react.createElement("div", keyValueList(props_28, 1), ...children_24)))) : singleton_1(Helpers_nothing)) : singleton_1(panel(pages));
        })))))));
    }
    let footerContents;
    let patternInput;
    const matchValue_5 = [maybeActivePatientInstDesignation, model.CurrentPage];
    let pattern_matching_result_1;
    if (matchValue_5[0] != null) {
        if (matchValue_5[0].tag === 1) {
            if (matchValue_5[1].tag === 4) {
                pattern_matching_result_1 = 1;
            }
            else if (matchValue_5[1].tag === 6) {
                if (matchValue_5[1].fields[0].tag === 1) {
                    pattern_matching_result_1 = 1;
                }
                else if (matchValue_5[1].fields[0].tag === 2) {
                    pattern_matching_result_1 = 1;
                }
                else {
                    pattern_matching_result_1 = 2;
                }
            }
            else {
                pattern_matching_result_1 = 2;
            }
        }
        else if (matchValue_5[1].tag === 4) {
            pattern_matching_result_1 = 0;
        }
        else if (matchValue_5[1].tag === 6) {
            if (matchValue_5[1].fields[0].tag === 1) {
                pattern_matching_result_1 = 0;
            }
            else if (matchValue_5[1].fields[0].tag === 2) {
                pattern_matching_result_1 = 0;
            }
            else {
                pattern_matching_result_1 = 2;
            }
        }
        else {
            pattern_matching_result_1 = 2;
        }
    }
    else {
        pattern_matching_result_1 = 2;
    }
    switch (pattern_matching_result_1) {
        case 0: {
            patternInput = ["WARNING: For Research Use Only.", defaultArg(map((x) => x.researchProductName, model.SystemInformationModel.maybeCurrentSystemInformation), ""), "footer-level-warning"];
            break;
        }
        case 1: {
            patternInput = ["WARNING: United States Federal law restricts this device to sale by or on the order of a physician.", defaultArg(map((x_1) => x_1.clinicalProductName, model.SystemInformationModel.maybeCurrentSystemInformation), ""), "footer-level-warning"];
            break;
        }
        case 2: {
            patternInput = ["", "", "footer-level"];
            break;
        }
    }
    footerContents = Brand_div(singleton(new Common_GenericOption(1, singleton(["style", {
        flexGrow: "1",
        minHeight: "inherit",
    }]))), singleton(columns(ofArray([new Option_2(9, singleton(new HTMLAttr(99, "footer-level"))), new Option_2(4), new Option_2(1)]), ofArray([column(singleton(new Option_1(3, singleton(new HTMLAttr(99, patternInput[2])))), singleton(patternInput[0])), column(singleton(new Option_1(3, singleton(["style", {
        textAlign: "center",
    }]))), singleton((arg_32 = (((copyOfStruct = now(), year(copyOfStruct))) | 0), toText(printf("© %d ViTAA Medical Solutions"))(arg_32)))), column(singleton(new Option_1(3, singleton(["style", {
        textAlign: "right",
    }]))), singleton(patternInput[1]))]))));
    const children_30 = [navbar, (children_28 = [columns(ofArray([new Option_2(3), new Option_2(9, singleton(["style", {
        backgroundColor: "#F0F0F0",
        marginBottom: "0px",
    }]))]), ofArray([menu, column(empty(), singleton(content_13))]))], react.createElement("div", {}, ...children_28)), sessionExpiryModal(model.AuthModel.inactivity, (arg_35) => {
        dispatch(new Msg(2, arg_35));
    }), navbar_1(ofArray([new Option_3(6, singleton(new HTMLAttr(99, "footer-desktop"))), new Option_3(4), new Option_3(2), new Option_3(8, singleton(new Modifier_IModifier(24, new Screen(5), true)))]), singleton(footerContents)), navbar_1(ofArray([new Option_3(6, singleton(new HTMLAttr(99, "footer-touch"))), new Option_3(8, singleton(new Modifier_IModifier(24, new Screen(1), true)))]), singleton(footerContents))];
    return react.createElement("div", {
        onDrop: (e) => {
            const droppedItem = e.dataTransfer.files.item(0);
            if (!isNullOrEmpty(droppedItem.type)) {
                e.preventDefault();
            }
        },
    }, ...children_30);
}

