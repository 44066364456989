import { contains, exists, map as map_1, fold, empty, append, ofArray, sortBy, sortByDescending, tryFind } from "../fable_modules/fable-library.3.7.20/List.js";
import { FSharpRef, toString as toString_1, Record, Union } from "../fable_modules/fable-library.3.7.20/Types.js";
import { float64_type, class_type, record_type, lambda_type, list_type, string_type, union_type } from "../fable_modules/fable-library.3.7.20/Reflection.js";
import { structuralHash, equals, compare } from "../fable_modules/fable-library.3.7.20/Util.js";
import { fromTicks, tryParse as tryParse_1, minValue, parse } from "../fable_modules/fable-library.3.7.20/DateOffset.js";
import { getTicks, toString as toString_2 } from "../fable_modules/fable-library.3.7.20/Date.js";
import { Result_ofOption } from "../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { ErrorMessage } from "./ErrorMessage.js";
import { ofNullable, toNullable, value as value_1, defaultArg, map } from "../fable_modules/fable-library.3.7.20/Option.js";
import { printf, toText, isNullOrWhiteSpace } from "../fable_modules/fable-library.3.7.20/String.js";
import { tryParse } from "../fable_modules/fable-library.3.7.20/Double.js";
import { isMatch } from "../fable_modules/fable-library.3.7.20/RegExp.js";
import { fromInteger, op_Modulus, op_Subtraction } from "../fable_modules/fable-library.3.7.20/Long.js";

export function Common_floatToMm(f) {
    return f * 1;
}

export function Common_floatToMl(f) {
    return f * 1;
}

export function Common_tryParse(toString, all, v) {
    return tryFind((x) => (toString(x) === v), all);
}

export const Common_guidCharacters = /^[1234567890abcdefABCDEF-]+$/gu;

export class Common_SortDirection extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Ascending", "Descending"];
    }
}

export function Common_SortDirection$reflection() {
    return union_type("RAWMap.Models.Common.SortDirection", [], Common_SortDirection, () => [[], []]);
}

export function Common_SortDirection_get_not() {
    return (_arg) => ((_arg.tag === 1) ? (new Common_SortDirection(0)) : (new Common_SortDirection(1)));
}

export class Common_SortInfo$1 extends Record {
    constructor(lastClicked, sortDirection, sortBy) {
        super();
        this.lastClicked = lastClicked;
        this.sortDirection = sortDirection;
        this.sortBy = sortBy;
    }
}

export function Common_SortInfo$1$reflection(gen0) {
    return record_type("RAWMap.Models.Common.SortInfo`1", [gen0], Common_SortInfo$1, () => [["lastClicked", string_type], ["sortDirection", Common_SortDirection$reflection()], ["sortBy", lambda_type(list_type(gen0), list_type(gen0))]]);
}

export function Common_SortInfo$1_getUpdatedSortInfo(header, optic, sortInfo) {
    const sortDirection = (sortInfo.lastClicked === header) ? Common_SortDirection_get_not()(sortInfo.sortDirection) : (new Common_SortDirection(0));
    return new Common_SortInfo$1(header, sortDirection, ((sortDirection.tag === 1) ? ((projection_1) => ((list_1) => sortByDescending(projection_1, list_1, {
        Compare: compare,
    }))) : ((projection) => ((list) => sortBy(projection, list, {
        Compare: compare,
    }))))((arg) => {
        const s = optic(arg);
        return s.toLowerCase();
    }));
}

export class Common_Sex extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Female", "Male"];
    }
}

export function Common_Sex$reflection() {
    return union_type("RAWMap.Models.Common.Sex", [], Common_Sex, () => [[], []]);
}

export function Common_Sex_get_all() {
    return ofArray([new Common_Sex(0), new Common_Sex(1)]);
}

export function Common_Sex_get_display() {
    return toString_1;
}

export function Common_Sex_get_tryParse() {
    const all = Common_Sex_get_all();
    return (v) => Common_tryParse(toString_1, all, v);
}

export function Common_Sex_get_stringSex_() {
    return [Common_Sex_get_tryParse(), (_arg) => ((_arg == null) ? null : toString_1(_arg))];
}

export class Common_YesNo extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Yes", "No"];
    }
}

export function Common_YesNo$reflection() {
    return union_type("RAWMap.Models.Common.YesNo", [], Common_YesNo, () => [[], []]);
}

export function Common_YesNo_get_all() {
    return ofArray([new Common_YesNo(0), new Common_YesNo(1)]);
}

export function Common_YesNo_get_display() {
    return toString_1;
}

export function Common_YesNo_get_tryParse() {
    const all = Common_YesNo_get_all();
    return (v) => Common_tryParse(toString_1, all, v);
}

export function Common_YesNo_get_stringYesNo_() {
    return [Common_YesNo_get_tryParse(), (_arg) => ((_arg == null) ? null : toString_1(_arg))];
}

export function System_DateTimeOffset__DateTimeOffset_truncateToMicrosecond_Static_Z53C0511E(dto) {
    return parse(toString_2(dto, "yyyy-MM-dd HH:mm:ss.ffffffK"));
}

export class Common_Treatment extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Open", "Evar"];
    }
}

export function Common_Treatment$reflection() {
    return union_type("RAWMap.Models.Common.Treatment", [], Common_Treatment, () => [[], []]);
}

export function Common_Treatment_get_all() {
    return ofArray([new Common_Treatment(0), new Common_Treatment(1)]);
}

export function Common_Treatment_get_describe() {
    return (_arg) => ((_arg.tag === 1) ? "EVAR" : "Open");
}

export function Common_Treatment_get_display() {
    return Common_Treatment_get_describe();
}

export function Common_Treatment_tryParse_Z721C83C5(treatment) {
    return tryFind((d) => (Common_Treatment_get_describe()(d) === treatment), Common_Treatment_get_all());
}

export function Common_Treatment_get_stringTreatment_() {
    return [Common_Treatment_tryParse_Z721C83C5, (_arg) => {
        if (_arg == null) {
            return null;
        }
        else {
            const s = _arg;
            return Common_Treatment_get_describe()(s);
        }
    }];
}

export class Common_Endoleak extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Ia", "Ib", "Iia", "Iib", "Iiia", "Iiib", "Unknown"];
    }
}

export function Common_Endoleak$reflection() {
    return union_type("RAWMap.Models.Common.Endoleak", [], Common_Endoleak, () => [[], [], [], [], [], [], []]);
}

export function Common_Endoleak_get_all() {
    return ofArray([new Common_Endoleak(0), new Common_Endoleak(1), new Common_Endoleak(2), new Common_Endoleak(3), new Common_Endoleak(4), new Common_Endoleak(5), new Common_Endoleak(6)]);
}

export function Common_Endoleak_get_describe() {
    return (_arg) => ((_arg.tag === 1) ? "Ib" : ((_arg.tag === 2) ? "IIa" : ((_arg.tag === 3) ? "IIb" : ((_arg.tag === 4) ? "IIIa" : ((_arg.tag === 5) ? "IIIb" : ((_arg.tag === 6) ? "Unknown" : "Ia"))))));
}

export function Common_Endoleak_get_display() {
    return Common_Endoleak_get_describe();
}

export function Common_Endoleak_tryParse_Z721C83C5(endoleak) {
    return tryFind((d) => (Common_Endoleak_get_describe()(d) === endoleak), Common_Endoleak_get_all());
}

export function Common_Endoleak_get_stringEndoleak_() {
    return [Common_Endoleak_tryParse_Z721C83C5, (_arg) => {
        if (_arg == null) {
            return null;
        }
        else {
            const s = _arg;
            return Common_Endoleak_get_describe()(s);
        }
    }];
}

export class Common_DeidentificationStatus extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Succeeded", "Failed"];
    }
}

export function Common_DeidentificationStatus$reflection() {
    return union_type("RAWMap.Models.Common.DeidentificationStatus", [], Common_DeidentificationStatus, () => [[], []]);
}

export class Common_StudyStatus extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["StudyInfoCreated", "DeidentificationFailed", "ReadyForAnalysis", "AnalysisInProgress", "AnalysisCompleted"];
    }
    toString() {
        const x = this;
        return Common_StudyStatus_get_describe()(x);
    }
}

export function Common_StudyStatus$reflection() {
    return union_type("RAWMap.Models.Common.StudyStatus", [], Common_StudyStatus, () => [[], [], [], [], []]);
}

export function Common_StudyStatus_get_all() {
    return ofArray([new Common_StudyStatus(0), new Common_StudyStatus(1), new Common_StudyStatus(2), new Common_StudyStatus(3), new Common_StudyStatus(4)]);
}

export function Common_StudyStatus_get_describe() {
    return (_arg) => ((_arg.tag === 1) ? "DeidentificationFailed" : ((_arg.tag === 2) ? "ReadyForAnalysis" : ((_arg.tag === 3) ? "AnalysisInProgress" : ((_arg.tag === 4) ? "AnalysisCompleted" : "StudyInfoCreated"))));
}

export function Common_StudyStatus_get_display() {
    return (_arg) => ((_arg.tag === 1) ? "DICOM Verification Failed" : ((_arg.tag === 2) ? "Ready For Analysis" : ((_arg.tag === 3) ? "Analysis In Progress" : ((_arg.tag === 4) ? "Analysis Completed" : "DICOM Verification Pending"))));
}

export function Common_StudyStatus_tryParse_Z721C83C5(value) {
    return tryFind((s) => (Common_StudyStatus_get_describe()(s) === value), Common_StudyStatus_get_all());
}

export function Common_StudyStatus_parse_Z721C83C5(value) {
    return Result_ofOption(new ErrorMessage(25), Common_StudyStatus_tryParse_Z721C83C5(value));
}

export class Common_ActiveBucketState extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Temporary", "Permanent"];
    }
}

export function Common_ActiveBucketState$reflection() {
    return union_type("RAWMap.Models.Common.ActiveBucketState", [], Common_ActiveBucketState, () => [[], []]);
}

export function Common_ActiveBucketState_getBucketState_Z58682287(status) {
    switch (status.tag) {
        case 1: {
            return new Common_ActiveBucketState(0);
        }
        case 2:
        case 3:
        case 4: {
            return new Common_ActiveBucketState(1);
        }
        default: {
            return new Common_ActiveBucketState(0);
        }
    }
}

export class Common_ReportType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Identified", "Deidentified"];
    }
}

export function Common_ReportType$reflection() {
    return union_type("RAWMap.Models.Common.ReportType", [], Common_ReportType, () => [[], []]);
}

export function Common_ReportType_get_all() {
    return ofArray([new Common_ReportType(0), new Common_ReportType(1)]);
}

export function Common_ReportType_get_display() {
    return toString_1;
}

export function Common_ReportType_get_tryParse() {
    const all = Common_ReportType_get_all();
    return (v) => Common_tryParse(toString_1, all, v);
}

export function Common_ReportType_get_stringReportType_() {
    return [Common_ReportType_get_tryParse(), (_arg) => ((_arg == null) ? null : toString_1(_arg))];
}

export class Common_GeneratedReportType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ViTAAReport", "CustomReport"];
    }
}

export function Common_GeneratedReportType$reflection() {
    return union_type("RAWMap.Models.Common.GeneratedReportType", [], Common_GeneratedReportType, () => [[], []]);
}

export function Common_GeneratedReportType_get_all() {
    return ofArray([new Common_GeneratedReportType(0), new Common_GeneratedReportType(1)]);
}

export function Common_GeneratedReportType_get_describe() {
    return (_arg) => ((_arg.tag === 1) ? "Custom Report" : "ViTAA Report");
}

export function Common_GeneratedReportType_tryParse_Z721C83C5(reportType) {
    return tryFind((d) => (Common_GeneratedReportType_get_describe()(d) === reportType), Common_GeneratedReportType_get_all());
}

export function Common_GeneratedReportType_get_describeOrDefault() {
    let f1;
    const mapping = Common_GeneratedReportType_get_describe();
    f1 = ((option) => map(mapping, option));
    return (arg) => defaultArg(f1(arg), "");
}

export const Subdivisions_provinces = ofArray(["Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland and Labrador", "Nova Scotia", "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan", "Northwest Territories", "Nunavut", "Yukon"]);

export const Subdivisions_states = ofArray(["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"]);

export const Subdivisions_all = append(Subdivisions_provinces, Subdivisions_states);

export function Subdivisions_getSubdivisions(_arg) {
    switch (_arg) {
        case "Canada": {
            return Subdivisions_provinces;
        }
        case "USA": {
            return Subdivisions_states;
        }
        default: {
            return Subdivisions_all;
        }
    }
}

export function Subdivisions_getSubdivisionsForValidation(_arg) {
    switch (_arg) {
        case "Canada": {
            return Subdivisions_provinces;
        }
        case "USA": {
            return Subdivisions_states;
        }
        default: {
            return empty();
        }
    }
}

export class PatientFilters_Field extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DateCreated", "StudyCreationDate", "StudyId", "StudyNumber", "Sex", "SmokingHistory", "AaaFamilyHistory", "Weight", "Height", "PostEvarEndoleakDetected", "HeartRate", "SystolicPressure", "DiastolicPressure", "Hypertension", "Copd", "DiabetesMellitus", "ConnectiveTissueDisorder", "Atherosclerosis", "IsPostTreatment", "PostTreatmentType", "PostTreatmentDate", "PostTreatmentEndoleakType", "PostEvarEndoleakRepair", "PostEvarGraftExplant", "AorticRupture", "LimbOcclusion"];
    }
}

export function PatientFilters_Field$reflection() {
    return union_type("RAWMap.Models.PatientFilters.Field", [], PatientFilters_Field, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export function PatientFilters_Field_get_all() {
    return ofArray([new PatientFilters_Field(0), new PatientFilters_Field(1), new PatientFilters_Field(2), new PatientFilters_Field(3), new PatientFilters_Field(4), new PatientFilters_Field(5), new PatientFilters_Field(6), new PatientFilters_Field(7), new PatientFilters_Field(8), new PatientFilters_Field(9), new PatientFilters_Field(10), new PatientFilters_Field(11), new PatientFilters_Field(12), new PatientFilters_Field(13), new PatientFilters_Field(14), new PatientFilters_Field(15), new PatientFilters_Field(16), new PatientFilters_Field(17), new PatientFilters_Field(18), new PatientFilters_Field(19), new PatientFilters_Field(20), new PatientFilters_Field(21), new PatientFilters_Field(22), new PatientFilters_Field(23), new PatientFilters_Field(24), new PatientFilters_Field(25)]);
}

export function PatientFilters_Field_get_viewDisplay() {
    return (_arg) => ((_arg.tag === 1) ? "Study Creation Date" : ((_arg.tag === 2) ? "Study ID" : ((_arg.tag === 3) ? "Study Number" : ((_arg.tag === 4) ? "Sex" : ((_arg.tag === 5) ? "Smoking History" : ((_arg.tag === 6) ? "AAA Family History" : ((_arg.tag === 7) ? "Weight" : ((_arg.tag === 8) ? "Height" : ((_arg.tag === 9) ? "Endoleak Detected" : ((_arg.tag === 10) ? "Heart Rate" : ((_arg.tag === 11) ? "Systolic Pressure" : ((_arg.tag === 12) ? "Diastolic Pressure" : ((_arg.tag === 13) ? "Hypertension" : ((_arg.tag === 14) ? "COPD" : ((_arg.tag === 15) ? "DiabetesMellitus" : ((_arg.tag === 16) ? "Connective Tissue Disorder" : ((_arg.tag === 17) ? "Atherosclerosis" : ((_arg.tag === 18) ? "Is Post Treatment" : ((_arg.tag === 19) ? "Post Treatment Type" : ((_arg.tag === 20) ? "Post Treatment Date" : ((_arg.tag === 21) ? "Post Treatment Endoleak Type" : ((_arg.tag === 22) ? "Endoleak Repair" : ((_arg.tag === 23) ? "Graft Explant" : ((_arg.tag === 24) ? "Aortic Rupture" : ((_arg.tag === 25) ? "Limb Occlusion" : "Patient Creation Date")))))))))))))))))))))))));
}

export function PatientFilters_Field_get_dbDisplay() {
    return (_arg) => ((_arg.tag === 1) ? "study_creation_date" : ((_arg.tag === 2) ? "study_id" : ((_arg.tag === 3) ? "study_number" : ((_arg.tag === 4) ? "sex" : ((_arg.tag === 5) ? "smoking_history" : ((_arg.tag === 6) ? "aaa_family_history" : ((_arg.tag === 7) ? "weight" : ((_arg.tag === 8) ? "height" : ((_arg.tag === 9) ? "post_evar_endoleak_detected" : ((_arg.tag === 10) ? "heart_rate" : ((_arg.tag === 11) ? "systolic_pressure" : ((_arg.tag === 12) ? "diastolic_pressure" : ((_arg.tag === 13) ? "hypertension" : ((_arg.tag === 14) ? "copd" : ((_arg.tag === 15) ? "diabetes_mellitus" : ((_arg.tag === 16) ? "connective_tissue_disorder" : ((_arg.tag === 17) ? "atherosclerosis" : ((_arg.tag === 18) ? "is_post_treatment" : ((_arg.tag === 19) ? "post_treat_treatment_type" : ((_arg.tag === 20) ? "post_treat_date" : ((_arg.tag === 21) ? "post_endoleak_endoleak_type" : ((_arg.tag === 22) ? "post_evar_endoleak_repair" : ((_arg.tag === 23) ? "post_evar_graft_explant" : ((_arg.tag === 24) ? "aortic_rupture" : ((_arg.tag === 25) ? "limb_occlusion" : "date_created")))))))))))))))))))))))));
}

export function PatientFilters_Field_get_getTableName() {
    return (_arg) => ((_arg.tag === 1) ? "studies" : ((_arg.tag === 2) ? "studies" : ((_arg.tag === 3) ? "studies" : ((_arg.tag === 4) ? "studies" : ((_arg.tag === 5) ? "studies" : ((_arg.tag === 6) ? "studies" : ((_arg.tag === 7) ? "studies" : ((_arg.tag === 8) ? "studies" : ((_arg.tag === 9) ? "studies" : ((_arg.tag === 10) ? "studies" : ((_arg.tag === 11) ? "studies" : ((_arg.tag === 12) ? "studies" : ((_arg.tag === 13) ? "studies" : ((_arg.tag === 14) ? "studies" : ((_arg.tag === 15) ? "studies" : ((_arg.tag === 16) ? "studies" : ((_arg.tag === 17) ? "studies" : ((_arg.tag === 18) ? "studies" : ((_arg.tag === 19) ? "studies" : ((_arg.tag === 20) ? "studies" : ((_arg.tag === 21) ? "studies" : ((_arg.tag === 22) ? "studies" : ((_arg.tag === 23) ? "studies" : ((_arg.tag === 24) ? "studies" : ((_arg.tag === 25) ? "studies" : "patients")))))))))))))))))))))))));
}

export class PatientFilters_FilterType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["YesNo", "Number", "Date", "Sex", "Endoleak", "PostTreatType", "Guid"];
    }
}

export function PatientFilters_FilterType$reflection() {
    return union_type("RAWMap.Models.PatientFilters.FilterType", [], PatientFilters_FilterType, () => [[], [], [], [], [], [], []]);
}

export function PatientFilters_FilterType_get_fieldToFilterType() {
    return (_arg) => ((_arg.tag === 20) ? (new PatientFilters_FilterType(2)) : ((_arg.tag === 1) ? (new PatientFilters_FilterType(2)) : ((_arg.tag === 10) ? (new PatientFilters_FilterType(1)) : ((_arg.tag === 11) ? (new PatientFilters_FilterType(1)) : ((_arg.tag === 12) ? (new PatientFilters_FilterType(1)) : ((_arg.tag === 8) ? (new PatientFilters_FilterType(1)) : ((_arg.tag === 7) ? (new PatientFilters_FilterType(1)) : ((_arg.tag === 3) ? (new PatientFilters_FilterType(1)) : ((_arg.tag === 4) ? (new PatientFilters_FilterType(3)) : ((_arg.tag === 19) ? (new PatientFilters_FilterType(5)) : ((_arg.tag === 21) ? (new PatientFilters_FilterType(4)) : ((_arg.tag === 5) ? (new PatientFilters_FilterType(0)) : ((_arg.tag === 6) ? (new PatientFilters_FilterType(0)) : ((_arg.tag === 9) ? (new PatientFilters_FilterType(0)) : ((_arg.tag === 13) ? (new PatientFilters_FilterType(0)) : ((_arg.tag === 14) ? (new PatientFilters_FilterType(0)) : ((_arg.tag === 15) ? (new PatientFilters_FilterType(0)) : ((_arg.tag === 16) ? (new PatientFilters_FilterType(0)) : ((_arg.tag === 17) ? (new PatientFilters_FilterType(0)) : ((_arg.tag === 18) ? (new PatientFilters_FilterType(0)) : ((_arg.tag === 22) ? (new PatientFilters_FilterType(0)) : ((_arg.tag === 23) ? (new PatientFilters_FilterType(0)) : ((_arg.tag === 24) ? (new PatientFilters_FilterType(0)) : ((_arg.tag === 25) ? (new PatientFilters_FilterType(0)) : ((_arg.tag === 2) ? (new PatientFilters_FilterType(6)) : (new PatientFilters_FilterType(2)))))))))))))))))))))))))));
}

export class PatientFilters_FilterComparison extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Equal", "LessThan", "LessEqual", "GreaterThan", "GreaterEqual"];
    }
}

export function PatientFilters_FilterComparison$reflection() {
    return union_type("RAWMap.Models.PatientFilters.FilterComparison", [], PatientFilters_FilterComparison, () => [[], [], [], [], []]);
}

export function PatientFilters_FilterComparison_get_all() {
    return ofArray([new PatientFilters_FilterComparison(0), new PatientFilters_FilterComparison(1), new PatientFilters_FilterComparison(2), new PatientFilters_FilterComparison(3), new PatientFilters_FilterComparison(4)]);
}

export function PatientFilters_FilterComparison_get_dbDisplay() {
    return (_arg) => ((_arg.tag === 1) ? "\u003c" : ((_arg.tag === 2) ? "\u003c=" : ((_arg.tag === 3) ? "\u003e" : ((_arg.tag === 4) ? "\u003e=" : "="))));
}

export function PatientFilters_FilterComparison_get_numberDisplay() {
    return (_arg) => ((_arg.tag === 1) ? "Is Less Than" : ((_arg.tag === 2) ? "Is Less Than or Equal to" : ((_arg.tag === 3) ? "Is Greater Than" : ((_arg.tag === 4) ? "Is Greater Than or Equal to" : "Is Equal to"))));
}

export function PatientFilters_FilterComparison_get_dateDisplay() {
    return (_arg) => ((_arg.tag === 1) ? "Before" : ((_arg.tag === 2) ? "On or Before" : ((_arg.tag === 3) ? "After" : ((_arg.tag === 4) ? "On or After" : "On"))));
}

export class PatientFilters_Filter extends Record {
    constructor(filter, dateFilter, filterField, filterType, comparison) {
        super();
        this.filter = filter;
        this.dateFilter = dateFilter;
        this.filterField = filterField;
        this.filterType = filterType;
        this.comparison = comparison;
    }
}

export function PatientFilters_Filter$reflection() {
    return record_type("RAWMap.Models.PatientFilters.Filter", [], PatientFilters_Filter, () => [["filter", string_type], ["dateFilter", class_type("System.DateTimeOffset")], ["filterField", PatientFilters_Field$reflection()], ["filterType", PatientFilters_FilterType$reflection()], ["comparison", PatientFilters_FilterComparison$reflection()]]);
}

export function PatientFilters_Filter_get_empty() {
    return new PatientFilters_Filter("", minValue(), new PatientFilters_Field(0), new PatientFilters_FilterType(2), new PatientFilters_FilterComparison(0));
}

export function PatientFilters_Filter_isValid_72A93D4F(filter) {
    let outArg, outArg_1;
    const matchValue = filter.filterType;
    switch (matchValue.tag) {
        case 5:
        case 3:
        case 4: {
            return !isNullOrWhiteSpace(filter.filter);
        }
        case 1: {
            return ((outArg = 0, [tryParse(filter.filter, new FSharpRef(() => outArg, (v) => {
                outArg = v;
            })), outArg]))[0];
        }
        case 2: {
            return ((outArg_1 = minValue(), [tryParse_1(filter.filter, new FSharpRef(() => outArg_1, (v_1) => {
                outArg_1 = v_1;
            })), outArg_1]))[0];
        }
        case 6: {
            return isMatch(Common_guidCharacters, filter.filter);
        }
        default: {
            return !isNullOrWhiteSpace(filter.filter);
        }
    }
}

export class Csv_CsvRow extends Record {
    constructor(displayName, value) {
        super();
        this.displayName = displayName;
        this.value = value;
    }
}

export function Csv_CsvRow$reflection() {
    return record_type("RAWMap.Models.Csv.CsvRow", [], Csv_CsvRow, () => [["displayName", string_type], ["value", string_type]]);
}

export class Csv_CsvRowMulti extends Record {
    constructor(displayName, values) {
        super();
        this.displayName = displayName;
        this.values = values;
    }
}

export function Csv_CsvRowMulti$reflection() {
    return record_type("RAWMap.Models.Csv.CsvRowMulti", [], Csv_CsvRowMulti, () => [["displayName", string_type], ["values", list_type(string_type)]]);
}

export function Csv_makeCsvMulti(fields) {
    return fold((x_1, y_1) => (x_1 + y_1), "", map_1((field) => {
        const rows = fold((x, y) => (x + y), "", map_1((v) => toText(printf("\"%s\","))(v), field.values));
        return toText(printf("\"%s\",%s\n"))(field.displayName)(rows);
    }, fields));
}

export function Csv_makeCsv(fields) {
    return fold((x, y) => (x + y), "", map_1((field) => toText(printf("\"%s\",\"%s\"\n"))(field.displayName)(field.value), fields));
}

export function Csv_makeCsvUrl(csv) {
    const uriEncodedCsv = encodeURIComponent(csv);
    return toText(printf("data:text/csv;charset=utf-8,%s%s"))("%EF%BB%BF")(uriEncodedCsv);
}

export function Csv_makeDownloadLink(fileName, url) {
    const link = window.document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    return link;
}

export function Csv_makeCsvMultiLink(fileName, rows) {
    return Csv_makeDownloadLink(fileName, Csv_makeCsvUrl(Csv_makeCsvMulti(rows)));
}

export function Csv_makeCsvLink(fileName, rows) {
    return Csv_makeDownloadLink(fileName, Csv_makeCsvUrl(Csv_makeCsv(rows)));
}

export class Visualization_Centerline extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Lumen", "Wall"];
    }
}

export function Visualization_Centerline$reflection() {
    return union_type("RAWMap.Models.Visualization.Centerline", [], Visualization_Centerline, () => [[], []]);
}

export function Visualization_Centerline_get_all() {
    return ofArray([new Visualization_Centerline(0), new Visualization_Centerline(1)]);
}

export function Visualization_Centerline_get_describe() {
    return (_arg) => ((_arg.tag === 1) ? "Wall" : "Lumen");
}

export function Visualization_Centerline_tryParse_Z721C83C5(value) {
    return tryFind((c) => (Visualization_Centerline_get_describe()(c) === value), Visualization_Centerline_get_all());
}

export class Visualization_UnifiedDistanceBranch extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Left", "Right", "Cusp"];
    }
}

export function Visualization_UnifiedDistanceBranch$reflection() {
    return union_type("RAWMap.Models.Visualization.UnifiedDistanceBranch", [], Visualization_UnifiedDistanceBranch, () => [[], [], []]);
}

export function Visualization_UnifiedDistanceBranch_get_all() {
    return ofArray([new Visualization_UnifiedDistanceBranch(0), new Visualization_UnifiedDistanceBranch(1), new Visualization_UnifiedDistanceBranch(2)]);
}

export function Visualization_UnifiedDistanceBranch_get_defaultBranch() {
    return new Visualization_UnifiedDistanceBranch(0);
}

export function Visualization_UnifiedDistanceBranch_get_describe() {
    return (_arg) => ((_arg.tag === 1) ? "Right" : ((_arg.tag === 2) ? "Cusp" : "Left"));
}

export function Visualization_UnifiedDistanceBranch_get_describeView() {
    return (_arg) => ((_arg.tag === 1) ? "Right Iliac" : ((_arg.tag === 2) ? "Aortic Bifurcation" : "Left Iliac"));
}

export function Visualization_UnifiedDistanceBranch_tryParse_Z721C83C5(value) {
    return tryFind((b) => (Visualization_UnifiedDistanceBranch_get_describe()(b) === value), Visualization_UnifiedDistanceBranch_get_all());
}

export class Visualization_UnifiedTransitionBranch extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Left", "Right"];
    }
}

export function Visualization_UnifiedTransitionBranch$reflection() {
    return union_type("RAWMap.Models.Visualization.UnifiedTransitionBranch", [], Visualization_UnifiedTransitionBranch, () => [[], []]);
}

export function Visualization_UnifiedTransitionBranch_get_all() {
    return ofArray([new Visualization_UnifiedTransitionBranch(0), new Visualization_UnifiedTransitionBranch(1)]);
}

export function Visualization_UnifiedTransitionBranch_get_defaultBranch() {
    return new Visualization_UnifiedTransitionBranch(0);
}

export function Visualization_UnifiedTransitionBranch_get_describe() {
    return (_arg) => ((_arg.tag === 1) ? "Right" : "Left");
}

export function Visualization_UnifiedTransitionBranch_get_describeView() {
    return (_arg) => ((_arg.tag === 1) ? "Right Iliac" : "Left Iliac");
}

export function Visualization_UnifiedTransitionBranch_tryParse_Z721C83C5(value) {
    return tryFind((b) => (Visualization_UnifiedTransitionBranch_get_describe()(b) === value), Visualization_UnifiedTransitionBranch_get_all());
}

export class Visualization_UnifiedBranch extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UnifiedDistanceBranch", "UnifiedTransitionBranch"];
    }
}

export function Visualization_UnifiedBranch$reflection() {
    return union_type("RAWMap.Models.Visualization.UnifiedBranch", [], Visualization_UnifiedBranch, () => [[["Item", Visualization_UnifiedDistanceBranch$reflection()]], [["Item", Visualization_UnifiedTransitionBranch$reflection()]]]);
}

export function Visualization_UnifiedBranch_get_all() {
    return (_arg) => ((_arg.tag === 1) ? map_1((arg_1) => (new Visualization_UnifiedBranch(1, arg_1)), Visualization_UnifiedTransitionBranch_get_all()) : map_1((arg) => (new Visualization_UnifiedBranch(0, arg)), Visualization_UnifiedDistanceBranch_get_all()));
}

export function Visualization_UnifiedBranch_get_defaultBranch() {
    return (_arg) => ((_arg.tag === 1) ? (new Visualization_UnifiedBranch(1, Visualization_UnifiedTransitionBranch_get_defaultBranch())) : (new Visualization_UnifiedBranch(0, Visualization_UnifiedDistanceBranch_get_defaultBranch())));
}

export function Visualization_UnifiedBranch_get_describe() {
    return (_arg) => ((_arg.tag === 1) ? Visualization_UnifiedTransitionBranch_get_describe()(_arg.fields[0]) : Visualization_UnifiedDistanceBranch_get_describe()(_arg.fields[0]));
}

export function Visualization_UnifiedBranch_get_describeView() {
    return (_arg) => ((_arg.tag === 1) ? Visualization_UnifiedTransitionBranch_get_describeView()(_arg.fields[0]) : Visualization_UnifiedDistanceBranch_get_describeView()(_arg.fields[0]));
}

export function Visualization_UnifiedBranch_get_toUnifiedDistanceBranch() {
    return (_arg) => {
        if (_arg.tag === 0) {
            return _arg.fields[0];
        }
        else {
            throw (new Error("Must be UnifiedDistanceBranch\\nParameter name: UnifiedBranch"));
        }
    };
}

export function Visualization_UnifiedBranch_get_toUnifiedTransitionBranch() {
    return (_arg) => {
        if (_arg.tag === 1) {
            return _arg.fields[0];
        }
        else {
            throw (new Error("Must be UnifiedTransitionBranch\\nParameter name: UnifiedBranch"));
        }
    };
}

export class Visualization_Point3d extends Record {
    constructor(x, y, z) {
        super();
        this.x = x;
        this.y = y;
        this.z = z;
    }
}

export function Visualization_Point3d$reflection() {
    return record_type("RAWMap.Models.Visualization.Point3d", [], Visualization_Point3d, () => [["x", float64_type], ["y", float64_type], ["z", float64_type]]);
}

export function Visualization_Point3d_ofTuple_Z7AD9E565(x, y, z) {
    return new Visualization_Point3d(x, y, z);
}

export function Visualization_Point3d_toTuple_395A7FEE(p) {
    return [p.x, p.y, p.z];
}

export class Visualization_LabelPositionPercentage extends Record {
    constructor(x, y) {
        super();
        this.x = x;
        this.y = y;
    }
}

export function Visualization_LabelPositionPercentage$reflection() {
    return record_type("RAWMap.Models.Visualization.LabelPositionPercentage", [], Visualization_LabelPositionPercentage, () => [["x", float64_type], ["y", float64_type]]);
}

export function Visualization_LabelPositionPercentage_ofTuple_7B00E9A0(x, y) {
    return new Visualization_LabelPositionPercentage(x, y);
}

export function Visualization_LabelPositionPercentage_toTuple_Z2BE39FCE(p) {
    return [p.x, p.y];
}

export function Functions_flip(func, b, a) {
    return func(a, b);
}

export function String_toOption(str) {
    if (isNullOrWhiteSpace(str)) {
        return void 0;
    }
    else {
        return str;
    }
}

export function String_fromOption(_arg) {
    if (_arg == null) {
        return null;
    }
    else {
        return value_1(_arg);
    }
}

export const String_stringOption_ = [String_fromOption, String_toOption];

export const String_trimString_ = [(str) => str.trim(), (str_1) => str_1.trim()];

export function DateTimeOffset_truncateToMicrosecond(dto) {
    const originalTicks = getTicks(dto);
    return fromTicks(op_Subtraction(originalTicks, op_Modulus(originalTicks, fromInteger(10, false, 2))), dto.offset);
}

export function DateTimeOffset_yyyyMMdd(dateTime) {
    return toString_2(dateTime, "yyyy/MM/dd");
}

export function DateTimeOffset_maybeParse(str) {
    let matchValue;
    let outArg = minValue();
    matchValue = [tryParse_1(str, new FSharpRef(() => outArg, (v) => {
        outArg = v;
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export const Guid_guidOption_ = [(_arg) => ((_arg == null) ? "00000000-0000-0000-0000-000000000000" : _arg), (_arg_1) => ((_arg_1 === "00000000-0000-0000-0000-000000000000") ? (void 0) : _arg_1)];

export function Morphism_flip(morphism_, morphism__1) {
    const morphism = [morphism_, morphism__1];
    return [morphism[1], morphism[0]];
}

export function Option_ofString(str) {
    return String_toOption(str);
}

export function Option_nullableOption_() {
    return [toNullable, ofNullable];
}

export function List_overlaps(list1, list2) {
    return exists((elem) => contains(elem, list1, {
        Equals: equals,
        GetHashCode: structuralHash,
    }), list2);
}

export function List_containsOneOf(values, list) {
    return exists((x) => contains(x, values, {
        Equals: equals,
        GetHashCode: structuralHash,
    }), list);
}

