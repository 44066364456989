import { DefaultActions_create, DefaultActions_terminate, DefaultActions_UpdateArgs$3, DefaultActions_runUpdate, DefaultActions_ChildModel$2, Child$1, ChildAbsentStrategy$1, Child_absentChildCheck } from "../../Common/Terminate.js";
import { update as update_1, init as init_1 } from "../../Visualization/Screenshot/ScreenshotState.js";
import { ScreenshotUpdateArgs, Mode as Mode_1 } from "../../Visualization/Screenshot/ScreenshotTypes.js";
import { update as update_2, init as init_2 } from "./AdditionalData/AdditionalDataState.js";
import { OutboundMsg, LocalMsg, ModelCustomReport, Mode as Mode_2 } from "./CustomReportTypes.js";
import { Cmd_OfFunc_attempt, Cmd_none, Cmd_map, Cmd_batch } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { append, singleton, tryFind, ofArray } from "../../fable_modules/fable-library.3.7.20/List.js";
import { some } from "../../fable_modules/fable-library.3.7.20/Option.js";
import { equals } from "../../fable_modules/fable-library.3.7.20/Util.js";
import { InstitutionClinicalDesignation } from "../../RAWMap.Models/View/Institution.js";
import { securedApi } from "../../Api.js";
import { Toast_errorToast } from "../../Common/General.js";
import { ErrorMessage_get_describe } from "../../RAWMap.Models/ErrorMessage.js";
import { AdditionalDataUpdateArgs } from "./AdditionalData/AdditionalDataTypes.js";
import { CustomReportViewModel } from "../../RAWMap.Models/View/CustomReport.js";
import { init as init_3, tryTerminate, update as update_3 } from "./ReportPrompt/ReportPromptState.js";
import { S3Context } from "../../Common/AwsCommon.js";
import { FileManagementUpdateArgs } from "../../FileManagement/FileManagementTypes.js";
import { ReportPromptInitArgs, ReportPromptUpdateArgs } from "./ReportPrompt/ReportPromptTypes.js";
import { printf, toText } from "../../fable_modules/fable-library.3.7.20/String.js";
import { getAllMeasurementsCsvRows, Args } from "./ReportPdf/Sections/MeasurementSection.js";
import { Shared_UniversalArgs } from "./ReportPdf/Sections/SharedSection.js";
import { Csv_makeCsvMultiLink, DateTimeOffset_yyyyMMdd, Csv_CsvRowMulti } from "../../RAWMap.Models/Common.js";
import { utcNow } from "../../fable_modules/fable-library.3.7.20/DateOffset.js";
import { update as update_4 } from "../../Common/InboundOutbound.js";

export const childAbsentStrategy = new ChildAbsentStrategy$1(0, (model) => Child_absentChildCheck(model.PromptComponent.child));

export function init(args) {
    const patternInput = init_1(new Mode_1(2), args.selectedStudy.patientId, args.selectedStudy.studyId);
    const patternInput_1 = init_2();
    return [new ModelCustomReport(args.selectedStudy, args.selectedPatient, args.patientInstitution, args.studyAnalysis, void 0, args.geometryData, args.growthData, args.maybeCurrentStudyAcquisitionDate, args.maybePreviousStudyAcquisitionDate, patternInput[0], patternInput_1[0], new Mode_2(0), new DefaultActions_ChildModel$2(new Child$1(1), childAbsentStrategy, "report prompt component")), Cmd_batch(ofArray([Cmd_map((arg) => (new LocalMsg(3, arg)), patternInput[1]), Cmd_map((arg_1) => (new LocalMsg(4, arg_1)), patternInput_1[1]), Cmd_OfFunc_result(new LocalMsg(1))]))];
}

export function updateInbound(_args, _msg, model) {
    return [model, Cmd_none(), Cmd_none()];
}

function setMode(model, mode) {
    return new ModelCustomReport(model.SelectedStudy, model.SelectedPatient, model.PatientInstitution, model.StudyAnalysis, model.MaybePreviousStudy, model.GeometryData, model.GrowthData, model.MaybeCurrentStudyAcquisitionDate, model.MaybePreviousStudyAcquisitionDate, model.ScreenshotModel, model.AdditionalDataModel, mode, model.PromptComponent);
}

export function updateLocal(args, msg, model) {
    let inputRecord, inputRecord_1, inputRecord_4;
    const matchValue = [msg, model];
    let pattern_matching_result, ex, status, studies, e, msg_2, msg_3, conclusion, metadata, msg_12, promptType, rows, e_1;
    if (matchValue[0].tag === 4) {
        if (matchValue[0].fields[0].tag === 0) {
            pattern_matching_result = 7;
            msg_3 = matchValue[0].fields[0].fields[0];
        }
        else if (matchValue[0].fields[0].fields[0].tag === 1) {
            pattern_matching_result = 8;
            conclusion = matchValue[0].fields[0].fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
            status = matchValue[0].fields[0].fields[0].fields[0];
        }
    }
    else if (matchValue[0].tag === 5) {
        if (matchValue[0].fields[0].elmishChainedMsg.tag === 0) {
            pattern_matching_result = 11;
            msg_12 = matchValue[0].fields[0];
        }
        else if (matchValue[0].fields[0].elmishChainedMsg.fields[0].tag === 0) {
            pattern_matching_result = 9;
        }
        else if (matchValue[0].fields[0].elmishChainedMsg.fields[0].tag === 2) {
            if (matchValue[0].fields[0].elmishChainedMsg.fields[0].fields[0] != null) {
                pattern_matching_result = 10;
                metadata = matchValue[0].fields[0].elmishChainedMsg.fields[0].fields[0];
            }
            else {
                pattern_matching_result = 9;
            }
        }
        else {
            pattern_matching_result = 1;
            status = matchValue[0].fields[0].elmishChainedMsg.fields[0].fields[0];
        }
    }
    else if (matchValue[0].tag === 3) {
        if (matchValue[0].fields[0].tag === 0) {
            pattern_matching_result = 5;
            msg_2 = matchValue[0].fields[0].fields[0];
        }
        else if (matchValue[0].fields[0].fields[0].tag === 1) {
            pattern_matching_result = 6;
        }
        else {
            pattern_matching_result = 1;
            status = matchValue[0].fields[0].fields[0].fields[0];
        }
    }
    else if (matchValue[0].tag === 1) {
        pattern_matching_result = 2;
    }
    else if (matchValue[0].tag === 2) {
        if (matchValue[0].fields[0].tag === 1) {
            pattern_matching_result = 4;
            e = matchValue[0].fields[0].fields[0];
        }
        else {
            pattern_matching_result = 3;
            studies = matchValue[0].fields[0].fields[0];
        }
    }
    else if (matchValue[0].tag === 6) {
        pattern_matching_result = 12;
    }
    else if (matchValue[0].tag === 7) {
        pattern_matching_result = 13;
        promptType = matchValue[0].fields[0];
    }
    else if (matchValue[0].tag === 9) {
        pattern_matching_result = 14;
    }
    else if (matchValue[0].tag === 8) {
        if (matchValue[0].fields[0].tag === 1) {
            pattern_matching_result = 16;
            e_1 = matchValue[0].fields[0].fields[0];
        }
        else {
            pattern_matching_result = 15;
            rows = matchValue[0].fields[0].fields[0];
        }
    }
    else {
        pattern_matching_result = 0;
        ex = matchValue[0].fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            console.error(some("CustomReport exception:"), ex);
            return [model, Cmd_none(), Cmd_none()];
        }
        case 1: {
            return [model, Cmd_none(), Cmd_OfFunc_result(new OutboundMsg(0, status))];
        }
        case 2: {
            if ((model.SelectedStudy.studyNumber > 0) && equals(model.PatientInstitution.clinicalDesignation, new InstitutionClinicalDesignation(0))) {
                return [model, Cmd_OfAsyncWith_either((x) => {
                    Cmd_OfAsync_start(x);
                }, securedApi(args.fileArgs.Token).getPatientStudies, model.SelectedStudy.patientId, (arg) => (new LocalMsg(2, arg)), (arg_1) => (new LocalMsg(0, arg_1))), Cmd_none()];
            }
            else {
                return [new ModelCustomReport(model.SelectedStudy, model.SelectedPatient, model.PatientInstitution, model.StudyAnalysis, void 0, model.GeometryData, model.GrowthData, model.MaybeCurrentStudyAcquisitionDate, model.MaybePreviousStudyAcquisitionDate, model.ScreenshotModel, model.AdditionalDataModel, model.Mode, model.PromptComponent), Cmd_none(), Cmd_none()];
            }
        }
        case 3: {
            return [new ModelCustomReport(model.SelectedStudy, model.SelectedPatient, model.PatientInstitution, model.StudyAnalysis, tryFind((study) => (study.studyNumber === (model.SelectedStudy.studyNumber - 1)), studies), model.GeometryData, model.GrowthData, model.MaybeCurrentStudyAcquisitionDate, model.MaybePreviousStudyAcquisitionDate, model.ScreenshotModel, model.AdditionalDataModel, model.Mode, model.PromptComponent), Cmd_none(), Cmd_none()];
        }
        case 4: {
            return [model, Toast_errorToast(ErrorMessage_get_describe()(e)), Cmd_none()];
        }
        case 5: {
            const patternInput = update_1(new ScreenshotUpdateArgs(args.fileArgs.Token, args.fileArgs.Context), msg_2, model.ScreenshotModel);
            return [new ModelCustomReport(model.SelectedStudy, model.SelectedPatient, model.PatientInstitution, model.StudyAnalysis, model.MaybePreviousStudy, model.GeometryData, model.GrowthData, model.MaybeCurrentStudyAcquisitionDate, model.MaybePreviousStudyAcquisitionDate, patternInput[0], model.AdditionalDataModel, model.Mode, model.PromptComponent), Cmd_map((arg_3) => (new LocalMsg(3, arg_3)), patternInput[1]), Cmd_none()];
        }
        case 6: {
            return [model, Cmd_none(), Cmd_none()];
        }
        case 7: {
            const patternInput_1 = update_2(new AdditionalDataUpdateArgs(args.fileArgs, args.customReport), msg_3, model.AdditionalDataModel);
            return [new ModelCustomReport(model.SelectedStudy, model.SelectedPatient, model.PatientInstitution, model.StudyAnalysis, model.MaybePreviousStudy, model.GeometryData, model.GrowthData, model.MaybeCurrentStudyAcquisitionDate, model.MaybePreviousStudyAcquisitionDate, model.ScreenshotModel, patternInput_1[0], model.Mode, model.PromptComponent), Cmd_map((arg_4) => (new LocalMsg(4, arg_4)), patternInput_1[1]), Cmd_none()];
        }
        case 8: {
            return [model, Cmd_none(), Cmd_OfFunc_result(new OutboundMsg(1, (inputRecord = args.customReport, new CustomReportViewModel(inputRecord.studyId, conclusion.maybeConclusion, inputRecord.maybeLastReportPath, inputRecord.maybeLastReportDate, inputRecord.maybeLastReportOutdated))))];
        }
        case 9: {
            return [model, Cmd_batch(singleton(Cmd_OfFunc_result(new LocalMsg(6)))), Cmd_none()];
        }
        case 10: {
            return [model, Cmd_OfFunc_result(new LocalMsg(6)), Cmd_OfFunc_result(new OutboundMsg(1, (inputRecord_1 = args.customReport, new CustomReportViewModel(inputRecord_1.studyId, inputRecord_1.maybeConclusion, metadata.maybeLastReportPath, metadata.maybeLastReportDate, metadata.maybeLastReportOutdated))))];
        }
        case 11: {
            const patternInput_2 = DefaultActions_runUpdate(new DefaultActions_UpdateArgs$3((msg_13, promptModel) => {
                let inputRecord_2, inputRecord_3;
                if (msg_13.tag === 1) {
                    return [promptModel, Cmd_none()];
                }
                else {
                    return update_3(new ReportPromptUpdateArgs((inputRecord_2 = args.fileArgs, new FileManagementUpdateArgs(inputRecord_2.Token, inputRecord_2.Credentials, (inputRecord_3 = args.fileArgs.Context, new S3Context(inputRecord_3.S3Config, inputRecord_3.S3Client, inputRecord_3.S3Expiry, args.fileArgs.Context.ActiveBucket)))), args.userRoles, args.customReport), msg_13.fields[0], promptModel);
                }
            }, model), model.PromptComponent, msg_12);
            return [new ModelCustomReport(model.SelectedStudy, model.SelectedPatient, model.PatientInstitution, model.StudyAnalysis, model.MaybePreviousStudy, model.GeometryData, model.GrowthData, model.MaybeCurrentStudyAcquisitionDate, model.MaybePreviousStudyAcquisitionDate, model.ScreenshotModel, model.AdditionalDataModel, model.Mode, patternInput_2[0]), Cmd_map((arg_7) => (new LocalMsg(5, arg_7)), patternInput_2[1]), Cmd_none()];
        }
        case 12: {
            const patternInput_3 = DefaultActions_terminate(tryTerminate, model.PromptComponent);
            return [new ModelCustomReport(model.SelectedStudy, model.SelectedPatient, model.PatientInstitution, model.StudyAnalysis, model.MaybePreviousStudy, model.GeometryData, model.GrowthData, model.MaybeCurrentStudyAcquisitionDate, model.MaybePreviousStudyAcquisitionDate, model.ScreenshotModel, model.AdditionalDataModel, model.Mode, patternInput_3[0]), Cmd_map((arg_8) => (new LocalMsg(5, arg_8)), patternInput_3[1]), Cmd_none()];
        }
        case 13: {
            const createPromptComponent = (model_2) => {
                const createdPrompt = init_3(new ReportPromptInitArgs(model_2.SelectedStudy, model_2.SelectedPatient, model_2.PatientInstitution, model_2.StudyAnalysis, model_2.MaybePreviousStudy, model_2.GeometryData, model_2.GrowthData, model_2.MaybeCurrentStudyAcquisitionDate, model_2.MaybePreviousStudyAcquisitionDate, args.userRoles, promptType));
                const patternInput_4 = DefaultActions_create(createdPrompt[0], createdPrompt[1], model_2.PromptComponent);
                return [new ModelCustomReport(model_2.SelectedStudy, model_2.SelectedPatient, model_2.PatientInstitution, model_2.StudyAnalysis, model_2.MaybePreviousStudy, model_2.GeometryData, model_2.GrowthData, model_2.MaybeCurrentStudyAcquisitionDate, model_2.MaybePreviousStudyAcquisitionDate, model_2.ScreenshotModel, model_2.AdditionalDataModel, model_2.Mode, patternInput_4[0]), Cmd_batch(singleton(Cmd_map((arg_9) => (new LocalMsg(5, arg_9)), patternInput_4[1]))), Cmd_none()];
            };
            const matchValue_1 = model.PromptComponent.child;
            switch (matchValue_1.tag) {
                case 1:
                case 2: {
                    return createPromptComponent(model);
                }
                default: {
                    const termination = tryTerminate(matchValue_1.fields[0]);
                    if (termination.tag === 1) {
                        return [model, Toast_errorToast(toText(printf("%s was unable to switch."))(model.PromptComponent.childComponentName)), Cmd_none()];
                    }
                    else {
                        termination.fields[0]();
                        return createPromptComponent(new ModelCustomReport(model.SelectedStudy, model.SelectedPatient, model.PatientInstitution, model.StudyAnalysis, model.MaybePreviousStudy, model.GeometryData, model.GrowthData, model.MaybeCurrentStudyAcquisitionDate, model.MaybePreviousStudyAcquisitionDate, model.ScreenshotModel, model.AdditionalDataModel, model.Mode, (inputRecord_4 = model.PromptComponent, new DefaultActions_ChildModel$2(new Child$1(2), inputRecord_4.childAbsentStrategy, inputRecord_4.childComponentName))));
                    }
                }
            }
        }
        case 14: {
            const measurementSectionArgs = new Args(model.SelectedStudy.studyId, void 0, model.MaybeCurrentStudyAcquisitionDate, model.MaybePreviousStudyAcquisitionDate);
            const measurementSectionUniArgs = new Shared_UniversalArgs(args.fileArgs.Token, args.fileArgs.Context);
            return [model, Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, () => getAllMeasurementsCsvRows(measurementSectionUniArgs, measurementSectionArgs), void 0, (arg_11) => (new LocalMsg(8, arg_11)), (arg_12) => (new LocalMsg(0, arg_12))), Cmd_none()];
        }
        case 15: {
            const studyId = model.SelectedStudy.studyId;
            const infoRows = ofArray([new Csv_CsvRowMulti("Patient Id", singleton(model.SelectedPatient.id)), new Csv_CsvRowMulti("Study Id", singleton(studyId)), new Csv_CsvRowMulti("Export Date", singleton(DateTimeOffset_yyyyMMdd(utcNow())))]);
            const fileName = toText(printf("study_measurements-%s.csv"))(studyId);
            return [model, Cmd_OfFunc_attempt((rows_1) => {
                const link = Csv_makeCsvMultiLink(fileName, rows_1);
                link.click();
            }, append(infoRows, rows), (arg_15) => (new LocalMsg(0, arg_15))), Cmd_none()];
        }
        case 16: {
            return [model, Toast_errorToast(ErrorMessage_get_describe()(e_1)), Cmd_none()];
        }
    }
}

export function update(token, msg, model) {
    return update_4(updateLocal, updateInbound, token, msg, model);
}

