import { Union, Record } from "../../../../fable_modules/fable-library.3.7.20/Types.js";
import { option_type, bool_type, tuple_type, union_type, record_type, string_type } from "../../../../fable_modules/fable-library.3.7.20/Reflection.js";
import { StudyViewModel$reflection } from "../../../../RAWMap.Models/View/Study.js";
import { FileManagementUpdateArgs$reflection } from "../../../../FileManagement/FileManagementTypes.js";
import { Common_YesNo, DateTimeOffset_yyyyMMdd, Common_ReportType, Common_ReportType$reflection, Common_GeneratedReportType, Common_GeneratedReportType$reflection } from "../../../../RAWMap.Models/Common.js";
import { AsyncResult_ignore, AsyncResultComputationExpression_AsyncResultBuilder__Zero, AsyncResult_ofError, AsyncResultComputationExpression_AsyncResultBuilder__Combine_Z6CC99CA7, Result_ofOption, AsyncResult_ofResult, AsyncResult_bind, AsyncResultComputationExpression_asyncResult, AsyncResultComputationExpression_AsyncResultBuilder__Return_1505, AsyncResult_ofSuccess, AsyncResult_ofAsync, AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93, AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B } from "../../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { S3_ListObjectsV2RequestParams, S3_ListObjectsV2RequestParams_get_create } from "../../../../bindings/Fable.Helpers.Aws.js";
import { awaitPromise } from "../../../../fable_modules/fable-library.3.7.20/Async.js";
import { toArray, defaultArg, value as value_3, map, bind } from "../../../../fable_modules/fable-library.3.7.20/Option.js";
import { getSignedUrlPromise } from "../../../../Common/AwsCommon.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../../../fable_modules/Fable.Promise.2.2.2/Promise.fs.js";
import { promise } from "../../../../fable_modules/Fable.Promise.2.2.2/PromiseImpl.fs.js";
import { fetch$ } from "../../../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { tryFind, empty } from "../../../../fable_modules/fable-library.3.7.20/List.js";
import { Pdf_Images_Basic_JsPdfImage_create, Pdf_Spacing_leftMargin, Pdf_Images_Basic_addImageAutoScale, Pdf_Spacing_narrowSpacingY, Pdf_Images_Basic_addImageAutoScaleMaxOfImageSize } from "../ReportPdfHelpers.js";
import { FSharpResult$2 } from "../../../../fable_modules/fable-library.3.7.20/Choice.js";
import { ErrorMessage } from "../../../../RAWMap.Models/ErrorMessage.js";
import { securedApi } from "../../../../Api.js";
import { printf, toText } from "../../../../fable_modules/fable-library.3.7.20/String.js";
import { safeHash, equals } from "../../../../fable_modules/fable-library.3.7.20/Util.js";
import { Shared_UniversalArgs$reflection } from "./SharedSection.js";
import { PatientCreationViewModel$reflection } from "../../../../RAWMap.Models/View/Patient.js";
import { toUniversalTime } from "../../../../fable_modules/fable-library.3.7.20/DateOffset.js";
import { contains } from "../../../../fable_modules/fable-library.3.7.20/Seq.js";

export class TitlePageSection_SharedFields extends Record {
    constructor(studyCreationDate) {
        super();
        this.studyCreationDate = studyCreationDate;
    }
}

export function TitlePageSection_SharedFields$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.TitlePageSection.SharedFields", [], TitlePageSection_SharedFields, () => [["studyCreationDate", string_type]]);
}

export class TitlePageSection_DeidentifiedFields extends Record {
    constructor(vitaaId) {
        super();
        this.vitaaId = vitaaId;
    }
}

export function TitlePageSection_DeidentifiedFields$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.TitlePageSection.DeidentifiedFields", [], TitlePageSection_DeidentifiedFields, () => [["vitaaId", string_type]]);
}

export class TitlePageSection_IdentifiedFields extends Record {
    constructor(patientName, dateOfBirth, hospitalPatientId) {
        super();
        this.patientName = patientName;
        this.dateOfBirth = dateOfBirth;
        this.hospitalPatientId = hospitalPatientId;
    }
}

export function TitlePageSection_IdentifiedFields$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.TitlePageSection.IdentifiedFields", [], TitlePageSection_IdentifiedFields, () => [["patientName", string_type], ["dateOfBirth", string_type], ["hospitalPatientId", string_type]]);
}

export class TitlePageSection_ReportType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Identified", "Deidentified"];
    }
}

export function TitlePageSection_ReportType$reflection() {
    return union_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.TitlePageSection.ReportType", [], TitlePageSection_ReportType, () => [[["Item", TitlePageSection_IdentifiedFields$reflection()]], [["Item", TitlePageSection_DeidentifiedFields$reflection()]]]);
}

export class TitlePageSection_Args extends Record {
    constructor(currentStudy, patientInfo, isPostTreatment, fileArgs, generatedReportType) {
        super();
        this.currentStudy = currentStudy;
        this.patientInfo = patientInfo;
        this.isPostTreatment = isPostTreatment;
        this.fileArgs = fileArgs;
        this.generatedReportType = generatedReportType;
    }
}

export function TitlePageSection_Args$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.TitlePageSection.Args", [], TitlePageSection_Args, () => [["currentStudy", StudyViewModel$reflection()], ["patientInfo", tuple_type(TitlePageSection_ReportType$reflection(), TitlePageSection_SharedFields$reflection())], ["isPostTreatment", bool_type], ["fileArgs", FileManagementUpdateArgs$reflection()], ["generatedReportType", Common_GeneratedReportType$reflection()]]);
}

export function TitlePageSection_getFile(fileArgs, uploadPath) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
        const parms = S3_ListObjectsV2RequestParams_get_create()(new S3_ListObjectsV2RequestParams(fileArgs.Context.S3Config.PermanentBucket, "/", uploadPath, uploadPath));
        const getS3Objects = fileArgs.Context.S3Client.listObjectsV2(parms);
        return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_ofAsync(awaitPromise(getS3Objects.promise())), (_arg) => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_ofSuccess(bind((s3Object) => {
            if (s3Object.Key == null) {
                return void 0;
            }
            else {
                return s3Object;
            }
        }, map((s3Objects_1) => s3Objects_1[0], bind((s3Objects) => {
            if (s3Objects.length !== 1) {
                return void 0;
            }
            else {
                return s3Objects;
            }
        }, _arg.Contents)))), (_arg_1) => {
            const maybeImageFile = _arg_1;
            if (maybeImageFile == null) {
                return AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, void 0);
            }
            else {
                const imageFile = maybeImageFile;
                return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_ofAsync(awaitPromise(getSignedUrlPromise(fileArgs.Context, value_3(imageFile.Key)))), (_arg_2) => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_ofAsync(awaitPromise(PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetch$(_arg_2, empty()).then((_arg_3) => (_arg_3.arrayBuffer()))))))), (_arg_4) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, [_arg_4, value_3(imageFile.Key)])));
            }
        }));
    });
}

export function TitlePageSection_drawInstitutionLogo(image, doc, titleBarYLoc, imageHeight) {
    const pageSize = doc.internal.pageSize;
    return Pdf_Images_Basic_addImageAutoScaleMaxOfImageSize(image, 0, titleBarYLoc, pageSize.getWidth(), imageHeight, doc);
}

export function TitlePageSection_drawStudyType(isPostTreatment, startY, doc) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
        const studyTypeName = isPostTreatment ? "EVAR-title-cover-titlebar.png" : "NativeAorta-title-cover-titlebar.png";
        return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_ofAsync(awaitPromise(PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetch$(studyTypeName, empty()).then((_arg) => (_arg.arrayBuffer()))))))), (_arg_1) => {
            const pageSize = doc.internal.pageSize;
            const pageWidth = pageSize.getWidth();
            const startY_1 = startY + Pdf_Spacing_narrowSpacingY;
            const height = 30;
            return AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, [Pdf_Images_Basic_addImageAutoScale(_arg_1, "StudyType", pageWidth / 2, startY_1, true, true, (pageWidth / 2) - Pdf_Spacing_leftMargin, height, doc), startY_1 + height]);
        });
    });
}

export function TitlePageSection_drawVitaaLogo(titleBarYLoc, doc) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_ofAsync(awaitPromise(PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetch$("ReportTitleBar.png", empty()).then((_arg) => (_arg.arrayBuffer()))))))), (_arg_1) => {
        const pageSize = doc.internal.pageSize;
        return AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, Pdf_Images_Basic_addImageAutoScale(_arg_1, "ViTAA Logo", 0, titleBarYLoc, true, true, pageSize.getWidth(), 90, doc));
    }));
}

export function TitlePageSection_drawCautionIcon(doc) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_ofAsync(awaitPromise(PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetch$("triangle-exclamation-solid.svg", empty()).then((_arg) => (_arg.arrayBuffer()))))))), (_arg_1) => {
        const pageSize = doc.internal.pageSize;
        Pdf_Images_Basic_addImageAutoScale(_arg_1, "Caution Triangle", 10, pageSize.height - 55, true, true, 10, 10, doc);
        return AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, 10 + 10);
    }));
}

function TitlePageSection_getPatientAndInstitution(token, currentStudy) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_bind((patients) => AsyncResult_ofResult(defaultArg(map((arg) => (new FSharpResult$2(0, arg)), tryFind((patient) => (patient.id === currentStudy.patientId), patients)), new FSharpResult$2(1, new ErrorMessage(10, "patient")))), securedApi(token).getPatients()), (_arg) => {
        const patient_1 = _arg;
        return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_bind((institutions) => AsyncResult_ofResult(defaultArg(map((arg_2) => (new FSharpResult$2(0, arg_2)), tryFind((institution) => (institution.id === patient_1.institutionId), institutions)), new FSharpResult$2(1, new ErrorMessage(10, "institution")))), securedApi(token).getInstitutions()), (_arg_1) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, [patient_1, _arg_1]));
    }));
}

export function TitlePageSection_getUploadPath(patient) {
    return toText(printf("%O/Logo/"))(patient.institutionId);
}

export function TitlePageSection_getUdiString(token, institution) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_bind((info) => AsyncResult_ofResult(defaultArg(map((arg) => (new FSharpResult$2(0, arg)), info), new FSharpResult$2(1, new ErrorMessage(10, "system information")))), securedApi(token).getSystemInformation()), (_arg) => {
        let matchValue;
        const systemInformation = _arg;
        return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_ofResult((matchValue = institution.clinicalDesignation, (matchValue == null) ? (new FSharpResult$2(1, new ErrorMessage(10, "Institution Clinical Designation"))) : ((matchValue.tag === 1) ? (new FSharpResult$2(0, systemInformation.clinicalUdi)) : (new FSharpResult$2(0, systemInformation.researchUdi))))), (_arg_1) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, `Product UDI: ${_arg_1}`));
    }));
}

export const TitlePageSection_infoFontSize = 14;

export const TitlePageSection_lineSpacing = 10;

function TitlePageSection_addText(text, startX, startY, fontName, fontSize, options, doc) {
    const previousFont = doc.getFontSize();
    doc.setFont(fontName);
    doc.setFontSize(fontSize);
    doc.text(text, startX, startY, options);
    return doc.setFontSize(previousFont);
}

export function TitlePageSection_addUdiString(udi, doc) {
    const pageSize = doc.internal.pageSize;
    const width = pageSize.getWidth() / 3;
    return TitlePageSection_addText(udi, 10, (pageSize.height - 25) - 10, "Arial", 11, {}, doc);
}

export function TitlePageSection_addPatientInfoText(text, startY, doc) {
    const pageSize = doc.internal.pageSize;
    return [startY + TitlePageSection_lineSpacing, TitlePageSection_addText(text, pageSize.getWidth() - Pdf_Spacing_leftMargin, startY, "TimesNewRoman", TitlePageSection_infoFontSize, {
        align: "right",
    }, doc)];
}

export function TitlePageSection_drawPatientInfo(patientInfo_, patientInfo__1, institutionName, startY, doc) {
    let tupledArg_3, tupledArg_2, text, tupledArg_1, tupledArg;
    const patientInfo = [patientInfo_, patientInfo__1];
    const startY_1 = startY + TitlePageSection_lineSpacing;
    const format = (label, content) => {
        const arg = label.toLocaleUpperCase();
        return toText(printf("%s: %s"))(arg)(content);
    };
    const reportType = patientInfo[0];
    (tupledArg_3 = ((tupledArg_2 = ((reportType.tag === 1) ? TitlePageSection_addPatientInfoText(format("Vitaa Id", reportType.fields[0].vitaaId), startY_1, doc) : ((text = reportType.fields[0], (tupledArg_1 = ((tupledArg = TitlePageSection_addPatientInfoText(format("Patient Name", text.patientName), startY_1, doc), TitlePageSection_addPatientInfoText(format("Date of Birth", text.dateOfBirth), tupledArg[0], tupledArg[1]))), TitlePageSection_addPatientInfoText(format("Patient Id", text.hospitalPatientId), tupledArg_1[0], tupledArg_1[1]))))), TitlePageSection_addPatientInfoText(format("Institution", institutionName), tupledArg_2[0], tupledArg_2[1]))), TitlePageSection_addPatientInfoText(format("Study Creation Date", patientInfo[1].studyCreationDate), tupledArg_3[0], tupledArg_3[1]));
}

export function TitlePageSection_drawCautionMessage(institution, startX, doc) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
        const pageSize = doc.internal.pageSize;
        const startY = pageSize.height - 50;
        return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_ofResult(Result_ofOption(new ErrorMessage(13), institution.clinicalDesignation)), (_arg) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, TitlePageSection_addText((_arg.tag === 0) ? "WARNING\n Research Use Only." : "WARNING\n United States Federal law restricts this device to sale by or on the order of a physician.", startX, startY, "Arial", 11, {}, doc)));
    });
}

export function TitlePageSection_create(doc, uniArgs, args) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, TitlePageSection_getPatientAndInstitution(uniArgs.token, args.currentStudy), (_arg) => {
        const institution = _arg[1];
        const uploadDestinationPath = TitlePageSection_getUploadPath(_arg[0]);
        return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, TitlePageSection_getUdiString(uniArgs.token, institution), (_arg_1) => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, TitlePageSection_getFile(args.fileArgs, uploadDestinationPath), (_arg_2) => {
            let institutionLogo, fileKey;
            const maybeFileArgs = _arg_2;
            const pageSize = doc.internal.pageSize;
            const pageWidth = pageSize.getWidth();
            const titleBarYLoc = pageSize.getHeight() / 5;
            const imageHeight = pageWidth / 2.8;
            return AsyncResultComputationExpression_AsyncResultBuilder__Combine_Z6CC99CA7(AsyncResultComputationExpression_asyncResult, equals(args.generatedReportType, new Common_GeneratedReportType(1)) ? AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, (maybeFileArgs == null) ? AsyncResult_ofResult(new FSharpResult$2(0, void 0)) : ((institutionLogo = maybeFileArgs[0], (fileKey = maybeFileArgs[1], AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, defaultArg(map(AsyncResult_ofSuccess, Pdf_Images_Basic_JsPdfImage_create(institutionLogo, fileKey)), AsyncResult_ofError(new ErrorMessage(6, "Image data", "valid"))), (_arg_3) => {
                TitlePageSection_drawInstitutionLogo(_arg_3, doc, titleBarYLoc, imageHeight);
                return AsyncResultComputationExpression_AsyncResultBuilder__Zero(AsyncResultComputationExpression_asyncResult);
            }))))), () => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult)) : AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, AsyncResult_ignore(TitlePageSection_drawVitaaLogo(titleBarYLoc, doc)), () => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult)), AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, TitlePageSection_drawStudyType(args.isPostTreatment, titleBarYLoc + imageHeight, doc), (_arg_6) => {
                const tupledArg = args.patientInfo;
                TitlePageSection_drawPatientInfo(tupledArg[0], tupledArg[1], institution.name, _arg_6[1], _arg_6[0]);
                return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, TitlePageSection_drawCautionIcon(doc), (_arg_7) => {
                    TitlePageSection_addUdiString(_arg_1, doc);
                    return AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, TitlePageSection_drawCautionMessage(institution, _arg_7, doc), (_arg_8) => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, _arg_8));
                });
            })));
        }));
    }));
}

export class TitlePageArgs extends Record {
    constructor(universalArgs, titlePageArgs) {
        super();
        this.universalArgs = universalArgs;
        this.titlePageArgs = titlePageArgs;
    }
}

export function TitlePageArgs$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.TitlePageArgs", [], TitlePageArgs, () => [["universalArgs", Shared_UniversalArgs$reflection()], ["titlePageArgs", TitlePageSection_Args$reflection()]]);
}

export class TitlePageArgsModule_TitlePageHelperArgs extends Record {
    constructor(universalArgs, selectedStudy, fileArgs, maybeReportGenerationType, patientCreationViewmodel, generatedReportType) {
        super();
        this.universalArgs = universalArgs;
        this.selectedStudy = selectedStudy;
        this.fileArgs = fileArgs;
        this.maybeReportGenerationType = maybeReportGenerationType;
        this.patientCreationViewmodel = patientCreationViewmodel;
        this.generatedReportType = generatedReportType;
    }
}

export function TitlePageArgsModule_TitlePageHelperArgs$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.TitlePageArgsModule.TitlePageHelperArgs", [], TitlePageArgsModule_TitlePageHelperArgs, () => [["universalArgs", Shared_UniversalArgs$reflection()], ["selectedStudy", StudyViewModel$reflection()], ["fileArgs", FileManagementUpdateArgs$reflection()], ["maybeReportGenerationType", option_type(Common_ReportType$reflection())], ["patientCreationViewmodel", PatientCreationViewModel$reflection()], ["generatedReportType", Common_GeneratedReportType$reflection()]]);
}

export function TitlePageArgsModule_mkTitlePageArgs(args) {
    return new TitlePageArgs(args.universalArgs, new TitlePageSection_Args(args.selectedStudy, [equals(args.maybeReportGenerationType, new Common_ReportType(0)) ? (new TitlePageSection_ReportType(0, new TitlePageSection_IdentifiedFields(toText(printf("%s %s"))(args.patientCreationViewmodel.nameDobVm.firstName)(args.patientCreationViewmodel.nameDobVm.lastName), DateTimeOffset_yyyyMMdd(toUniversalTime(args.patientCreationViewmodel.nameDobVm.dateOfBirth)), args.patientCreationViewmodel.hospitalPatientIdVm.hospitalPatientId))) : (new TitlePageSection_ReportType(1, new TitlePageSection_DeidentifiedFields(args.patientCreationViewmodel.id))), new TitlePageSection_SharedFields(DateTimeOffset_yyyyMMdd(args.selectedStudy.studyCreationDate))], contains(new Common_YesNo(0), toArray(args.selectedStudy.isPostTreatment), {
        Equals: equals,
        GetHashCode: safeHash,
    }), args.fileArgs, args.generatedReportType));
}

