import { head, tail, isEmpty, singleton, tryFind, ofArray, find, empty } from "../fable_modules/fable-library.3.7.20/List.js";
import { Msg, Model } from "./StudyTypes.js";
import { Cmd_map, Cmd_batch, Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { toConsole, printf, toText } from "../fable_modules/fable-library.3.7.20/String.js";
import { bind, map, defaultArg, some } from "../fable_modules/fable-library.3.7.20/Option.js";
import { Cmd_OfAsyncWith_either, Cmd_OfAsync_start, Cmd_OfAsyncWith_result, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { PatientInfo as PatientInfo_1, InternalMsg, InboundMsg as InboundMsg_1, MsgCore, LocalMsg, wrapLocalMsg } from "./CoreForm/CoreFormTypes.js";
import { wrapLocalMsg as wrapLocalMsg_1, wrapInboundMsg } from "../Common/InboundOutbound.js";
import { InboundMsg } from "./CoreForm/CoreFormFileManagement/CoreFormFileTypes.js";
import { Async_map } from "../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { sleep } from "../fable_modules/fable-library.3.7.20/Async.js";
import { equals } from "../fable_modules/fable-library.3.7.20/Util.js";
import { Navigation_modifyUrl, Navigation_newUrl } from "../fable_modules/Fable.Elmish.Browser.3.0.5/navigation.fs.js";
import { Toast_errorToast, Urls_Page, Urls_PatientPage, Urls_pageHash } from "../Common/General.js";
import { initWithCreate as initWithCreate_1, initWithDisplay as initWithDisplay_1, updateCore } from "./CoreForm/CoreFormState.js";
import { securedApi } from "../Api.js";
import { Common_StudyStatus } from "../RAWMap.Models/Common.js";
import { empty as empty_1, singleton as singleton_1, append, delay as delay_1, toList } from "../fable_modules/fable-library.3.7.20/Seq.js";
import { initWithCreate, initWithDisplay, updateAnalysis } from "./AnalysisForm/AnalysisFormState.js";
import { tryPick } from "../fable_modules/fable-library.3.7.20/Map.js";
import { updateVisualization, init as init_1 } from "../Visualization/VisualizationState.js";
import { InboundMsg as InboundMsg_2 } from "../Visualization/VisualizationTypes.js";
import { MsgAnalysis, InternalMsg as InternalMsg_1, InboundMsg as InboundMsg_3, PatientInfo } from "./AnalysisForm/AnalysisFormTypes.js";
import { update as update_1, init as init_2 } from "./CustomReport/CustomReportState.js";
import { CustomReportUpdateArgs, CustomReportInitArgs } from "./CustomReport/CustomReportTypes.js";
import { FileManagementUpdateArgs } from "../FileManagement/FileManagementTypes.js";
import { Permission, isAllowed } from "../RAWMap.Models/Security.js";
import { CustomReportViewModel, CustomReportStatus_isNowOutdated_73E1A31F, CustomReportViewModel_empty_244AC511 } from "../RAWMap.Models/View/CustomReport.js";
import { ErrorMessage_get_describe } from "../RAWMap.Models/ErrorMessage.js";

export function init() {
    return [new Model(void 0, empty(), empty(), empty(), "00000000-0000-0000-0000-000000000000", void 0, void 0, void 0, void 0, false, false, void 0, void 0, void 0, void 0, void 0, null, void 0), Cmd_none()];
}

export function update(token, msg, model) {
    let _patientInst, matchValue_2, studyId_1, matchValue_4, tupledArg, tupledArg_1, tupledArg_5, tupledArg_6;
    let pattern_matching_result, ex, studyId, commandAfterRefresh, studyGuid, delay, msg_9, runCheck_1, command_1, svm, msg_22, avm, avm_1, avm_2, msg_35, selectedStudy, studyId_3, analysis_1, analysis_2, msg_47, customReportData, selectedStudy_1, msg_49, data, studyId_4, data_1, err, status, userInfo, cognitoCredentials, s3Context_1, pid, studies, e, sid_3, patientInstitution_1, study_12, patientId, toSelect, toSelect_1, toSelect_2, pid_1, patients, institutions, e_1, e_2;
    if (msg.tag === 1) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 7;
            msg_22 = msg.fields[0].fields[0];
        }
        else if (msg.fields[0].fields[0].tag === 2) {
            pattern_matching_result = 2;
            commandAfterRefresh = msg.fields[0].fields[0].fields[0][1];
            studyGuid = msg.fields[0].fields[0].fields[0][0];
        }
        else if (msg.fields[0].fields[0].tag === 1) {
            pattern_matching_result = 5;
            svm = msg.fields[0].fields[0].fields[0];
        }
        else if (msg.fields[0].fields[0].tag === 3) {
            pattern_matching_result = 6;
        }
        else {
            pattern_matching_result = 1;
            studyId = msg.fields[0].fields[0].fields[0];
        }
    }
    else if (msg.tag === 2) {
        pattern_matching_result = 3;
        delay = msg.fields[1];
        msg_9 = msg;
        runCheck_1 = msg.fields[0];
    }
    else if (msg.tag === 3) {
        pattern_matching_result = 4;
        command_1 = msg.fields[0];
    }
    else if (msg.tag === 4) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 14;
            msg_35 = msg.fields[0].fields[0];
        }
        else if (msg.fields[0].fields[0].tag === 1) {
            pattern_matching_result = 9;
            avm_1 = msg.fields[0].fields[0].fields[0];
        }
        else if (msg.fields[0].fields[0].tag === 2) {
            pattern_matching_result = 10;
        }
        else if (msg.fields[0].fields[0].tag === 3) {
            pattern_matching_result = 13;
        }
        else if (msg.fields[0].fields[0].tag === 4) {
            pattern_matching_result = 15;
            selectedStudy = msg.fields[0].fields[0].fields[0];
        }
        else {
            pattern_matching_result = 8;
            avm = msg.fields[0].fields[0].fields[0];
        }
    }
    else if (msg.tag === 5) {
        pattern_matching_result = 11;
        avm_2 = msg.fields[0];
    }
    else if (msg.tag === 6) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 50;
            e_2 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 12;
        }
    }
    else if (msg.tag === 7) {
        pattern_matching_result = 16;
        studyId_3 = msg.fields[0];
    }
    else if (msg.tag === 8) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 49;
            e_1 = msg.fields[0].fields[0];
        }
        else if (msg.fields[0].fields[0] == null) {
            pattern_matching_result = 18;
        }
        else {
            pattern_matching_result = 17;
            analysis_1 = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 9) {
        pattern_matching_result = 19;
    }
    else if (msg.tag === 10) {
        pattern_matching_result = 20;
        analysis_2 = msg.fields[0];
    }
    else if (msg.tag === 11) {
        pattern_matching_result = 21;
    }
    else if (msg.tag === 12) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 2) {
                pattern_matching_result = 24;
                customReportData = msg.fields[0].fields[0].fields[1];
                selectedStudy_1 = msg.fields[0].fields[0].fields[0];
            }
            else if (msg.fields[0].fields[0].tag === 0) {
                pattern_matching_result = 32;
                status = msg.fields[0].fields[0].fields[0];
            }
            else {
                pattern_matching_result = 23;
            }
        }
        else {
            pattern_matching_result = 22;
            msg_47 = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 13) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 2) {
                pattern_matching_result = 27;
            }
            else if (msg.fields[0].fields[0].tag === 0) {
                pattern_matching_result = 32;
                status = msg.fields[0].fields[0].fields[0];
            }
            else {
                pattern_matching_result = 26;
                data = msg.fields[0].fields[0].fields[0];
            }
        }
        else {
            pattern_matching_result = 25;
            msg_49 = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 14) {
        pattern_matching_result = 28;
        studyId_4 = msg.fields[0];
    }
    else if (msg.tag === 15) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 31;
            err = msg.fields[0].fields[0];
        }
        else if (msg.fields[0].fields[0] == null) {
            pattern_matching_result = 30;
        }
        else {
            pattern_matching_result = 29;
            data_1 = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 16) {
        pattern_matching_result = 33;
        userInfo = msg.fields[0];
    }
    else if (msg.tag === 31) {
        pattern_matching_result = 34;
        cognitoCredentials = msg.fields[0];
        s3Context_1 = msg.fields[1];
    }
    else if (msg.tag === 17) {
        pattern_matching_result = 35;
        pid = msg.fields[0];
    }
    else if (msg.tag === 18) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 37;
            e = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 36;
            studies = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 26) {
        pattern_matching_result = 38;
        sid_3 = msg.fields[0];
    }
    else if (msg.tag === 27) {
        pattern_matching_result = 39;
        patientInstitution_1 = msg.fields[1];
        study_12 = msg.fields[0];
    }
    else if (msg.tag === 28) {
        pattern_matching_result = 40;
        patientId = msg.fields[0];
    }
    else if (msg.tag === 25) {
        pattern_matching_result = 41;
    }
    else if (msg.tag === 19) {
        pattern_matching_result = 42;
        toSelect = msg.fields[0];
    }
    else if (msg.tag === 20) {
        pattern_matching_result = 43;
        toSelect_1 = msg.fields[0];
    }
    else if (msg.tag === 21) {
        pattern_matching_result = 44;
        toSelect_2 = msg.fields[0];
    }
    else if (msg.tag === 22) {
        pattern_matching_result = 45;
        pid_1 = msg.fields[0];
    }
    else if (msg.tag === 29) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 50;
            e_2 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 46;
            patients = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 30) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 50;
            e_2 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 47;
            institutions = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 23) {
        pattern_matching_result = 48;
    }
    else if (msg.tag === 24) {
        if (msg.fields[0].tag === 4) {
            pattern_matching_result = 51;
        }
        else {
            pattern_matching_result = 52;
        }
    }
    else {
        pattern_matching_result = 0;
        ex = msg.fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            console.error(some(toText(printf("Error: %O"))(ex)));
            return [model, Cmd_none()];
        }
        case 1: {
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, studyId, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_none()];
        }
        case 2: {
            const model$0027 = new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, true, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context);
            return [model$0027, Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(1, wrapLocalMsg(new LocalMsg(2, wrapInboundMsg(new InboundMsg(0)))))), Cmd_OfFunc_result(new Msg(17, model$0027.PatientGuid)), Cmd_OfFunc_result(new Msg(2, (model_1) => {
                if (model_1.isStudiesLoading) {
                    return void 0;
                }
                else {
                    return Cmd_map((arg_3) => (new Msg(1, new MsgCore(0, arg_3))), commandAfterRefresh(find((study) => (study.studyId === studyGuid), model_1.Studies)));
                }
            }, 1000))]))];
        }
        case 3: {
            const matchValue = runCheck_1(model);
            if (matchValue == null) {
                return [model, Cmd_OfAsyncWith_result((x) => {
                    Cmd_OfAsync_start(x);
                }, Async_map(() => msg_9, sleep(delay)))];
            }
            else {
                return [model, Cmd_OfFunc_result(new Msg(3, matchValue))];
            }
        }
        case 4: {
            return [model, command_1];
        }
        case 5: {
            if (model.PatientInstitution != null) {
                if ((_patientInst = model.PatientInstitution, equals(model.StudyGuid, svm.studyId))) {
                    const _patientInst_1 = model.PatientInstitution;
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, svm.studyId, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(17, model.PatientGuid)), Cmd_OfFunc_result(new Msg(1, new MsgCore(0, new InternalMsg(1, new InboundMsg_1(0, svm)))))]))];
                }
                else {
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, svm.studyId, void 0, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(17, model.PatientGuid)), Navigation_newUrl(Urls_pageHash(new Urls_Page(6, new Urls_PatientPage(2, [svm.patientId, svm.studyId]))))]))];
                }
            }
            else {
                return [model, Cmd_batch(ofArray([Toast_errorToast("No patient institution found"), Cmd_OfFunc_result(new Msg(22, model.PatientGuid))]))];
            }
        }
        case 6: {
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, void 0, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_batch(ofArray([Navigation_newUrl(Urls_pageHash(new Urls_Page(6, new Urls_PatientPage(1, model.PatientGuid)))), Cmd_OfFunc_result(new Msg(22, model.PatientGuid))]))];
        }
        case 7: {
            const matchValue_1 = model.CoreFormComponent;
            if (matchValue_1 == null) {
                let pattern_matching_result_1;
                if (msg_22.tag === 0) {
                    if (msg_22.fields[0].tag === 2) {
                        if (msg_22.fields[0].fields[0].tag === 1) {
                            if (msg_22.fields[0].fields[0].fields[0].tag === 4) {
                                pattern_matching_result_1 = 0;
                            }
                            else {
                                pattern_matching_result_1 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                }
                else {
                    pattern_matching_result_1 = 1;
                }
                switch (pattern_matching_result_1) {
                    case 0: {
                        return [model, Cmd_none()];
                    }
                    case 1: {
                        console.error(some(toText(printf("CoreForm does not exist. Message: %A"))(msg_22)));
                        return [model, Toast_errorToast("CoreForm does not exist")];
                    }
                }
            }
            else {
                const patternInput = updateCore(token, msg_22, matchValue_1);
                return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, patternInput[0], model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_map((arg_13) => (new Msg(1, arg_13)), patternInput[1])];
            }
        }
        case 8: {
            const activeStudy = tryFind((study_1) => (study_1.studyId === avm.studyId), model.Studies);
            let pattern_matching_result_2;
            if (activeStudy == null) {
                pattern_matching_result_2 = 0;
            }
            else if (activeStudy.status.tag === 4) {
                pattern_matching_result_2 = 0;
            }
            else {
                pattern_matching_result_2 = 1;
            }
            switch (pattern_matching_result_2) {
                case 0: {
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, void 0, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_OfFunc_result(new Msg(7, avm.studyId))];
                }
                case 1: {
                    return [model, Cmd_OfFunc_result(new Msg(5, avm))];
                }
            }
        }
        case 9: {
            return [model, Cmd_OfFunc_result(new Msg(5, avm_1))];
        }
        case 10: {
            return [model, Cmd_batch((matchValue_2 = model.StudyGuid, (matchValue_2 == null) ? singleton(Toast_errorToast("Unable to find selected study")) : ((studyId_1 = matchValue_2, singleton(Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, securedApi(token).updateStudyStatus, [studyId_1, new Common_StudyStatus(3)], (arg_17) => (new Msg(6, arg_17)), (arg_18) => (new Msg(0, arg_18))))))))];
        }
        case 11: {
            let studyCmd_1;
            const matchValue_3 = model.StudyGuid;
            if (matchValue_3 == null) {
                studyCmd_1 = singleton(Toast_errorToast("Unable to find selected study"));
            }
            else {
                const studyId_2 = matchValue_3;
                studyCmd_1 = singleton(Cmd_OfAsyncWith_either((x_2) => {
                    Cmd_OfAsync_start(x_2);
                }, securedApi(token).updateStudyStatus, [studyId_2, new Common_StudyStatus(4)], (arg_20) => (new Msg(6, arg_20)), (arg_21) => (new Msg(0, arg_21))));
            }
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, void 0, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_batch(toList(delay_1(() => append(singleton_1(Cmd_OfFunc_result(new Msg(7, avm_2.studyId))), delay_1(() => studyCmd_1)))))];
        }
        case 12: {
            return [model, Cmd_OfFunc_result(new Msg(17, model.PatientGuid))];
        }
        case 13: {
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, void 0, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), (matchValue_4 = model.SelectedAnalysis, (matchValue_4 != null) ? Cmd_OfFunc_result(new Msg(10, matchValue_4)) : Cmd_none())];
        }
        case 14: {
            const matchValue_5 = model.AnalysisFormComponent;
            if (matchValue_5 == null) {
                let pattern_matching_result_3;
                if (msg_35.tag === 0) {
                    if (msg_35.fields[0].tag === 3) {
                        if (msg_35.fields[0].fields[0].tag === 0) {
                            if (msg_35.fields[0].fields[0].fields[0].tag === 0) {
                                if (msg_35.fields[0].fields[0].fields[0].fields[0].tag === 2) {
                                    if (msg_35.fields[0].fields[0].fields[0].fields[0].fields[0].tag === 0) {
                                        if (msg_35.fields[0].fields[0].fields[0].fields[0].fields[0].fields[0].tag === 0) {
                                            if (msg_35.fields[0].fields[0].fields[0].fields[0].fields[0].fields[0].fields[0].tag === 0) {
                                                pattern_matching_result_3 = 0;
                                            }
                                            else {
                                                pattern_matching_result_3 = 1;
                                            }
                                        }
                                        else {
                                            pattern_matching_result_3 = 1;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_3 = 1;
                                    }
                                }
                                else {
                                    pattern_matching_result_3 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_3 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_3 = 1;
                        }
                    }
                    else if (msg_35.fields[0].tag === 4) {
                        if (msg_35.fields[0].fields[0].tag === 0) {
                            if (msg_35.fields[0].fields[0].fields[0].tag === 0) {
                                if (msg_35.fields[0].fields[0].fields[0].fields[0].tag === 2) {
                                    if (msg_35.fields[0].fields[0].fields[0].fields[0].fields[0].tag === 0) {
                                        if (msg_35.fields[0].fields[0].fields[0].fields[0].fields[0].fields[0].tag === 0) {
                                            if (msg_35.fields[0].fields[0].fields[0].fields[0].fields[0].fields[0].fields[0].tag === 0) {
                                                pattern_matching_result_3 = 0;
                                            }
                                            else {
                                                pattern_matching_result_3 = 1;
                                            }
                                        }
                                        else {
                                            pattern_matching_result_3 = 1;
                                        }
                                    }
                                    else {
                                        pattern_matching_result_3 = 1;
                                    }
                                }
                                else {
                                    pattern_matching_result_3 = 1;
                                }
                            }
                            else {
                                pattern_matching_result_3 = 1;
                            }
                        }
                        else {
                            pattern_matching_result_3 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_3 = 1;
                    }
                }
                else {
                    pattern_matching_result_3 = 1;
                }
                switch (pattern_matching_result_3) {
                    case 0: {
                        return [model, Cmd_none()];
                    }
                    case 1: {
                        return [model, Toast_errorToast("AnalysisForm does not exist")];
                    }
                }
            }
            else {
                const patternInput_1 = updateAnalysis(token, msg_35, matchValue_5);
                return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, patternInput_1[0], model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_map((arg_23) => (new Msg(4, arg_23)), patternInput_1[1])];
            }
        }
        case 15: {
            const matchValue_6 = [model.S3Context, model.AnalysisFormComponent, model.PatientInstitution];
            let pattern_matching_result_4, analysisModel, context, patientInstitution;
            if (matchValue_6[0] == null) {
                pattern_matching_result_4 = 2;
            }
            else if (matchValue_6[1] != null) {
                if (matchValue_6[2] != null) {
                    pattern_matching_result_4 = 0;
                    analysisModel = matchValue_6[1];
                    context = matchValue_6[0];
                    patientInstitution = matchValue_6[2];
                }
                else {
                    pattern_matching_result_4 = 1;
                }
            }
            else {
                pattern_matching_result_4 = 1;
            }
            switch (pattern_matching_result_4) {
                case 0: {
                    const matchValue_7 = [tryPick((_arg, vm) => vm, analysisModel.UiDataFileManager.fileUpload.DownloadableFiles), patientInstitution.clinicalDesignation];
                    if (matchValue_7[0] == null) {
                        return [model, Toast_errorToast("Missing Visualization data files, please contact ViTAA support")];
                    }
                    else if (matchValue_7[1] == null) {
                        return [model, Toast_errorToast("Missing Institution Designation")];
                    }
                    else {
                        const designation = matchValue_7[1];
                        const uiFileManagementVm = matchValue_7[0];
                        const patternInput_2 = init_1(context, model.Studies, model.PatientGuid, selectedStudy, uiFileManagementVm.Name, designation, model.MaybeCurrentUser);
                        return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, patternInput_2[0], model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_batch(ofArray([Cmd_map((arg_25) => (new Msg(12, wrapLocalMsg_1(arg_25))), patternInput_2[1]), Cmd_OfFunc_result(new Msg(12, wrapInboundMsg(new InboundMsg_2(1))))]))];
                    }
                }
                case 1: {
                    return [model, Toast_errorToast("Missing Analysis data, please contact ViTAA support")];
                }
                case 2: {
                    return [model, Toast_errorToast("Missing S3 Context")];
                }
            }
        }
        case 16: {
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, true, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_OfAsyncWith_either((x_3) => {
                Cmd_OfAsync_start(x_3);
            }, securedApi(token).tryGetAnalysis, studyId_3, (arg_27) => (new Msg(8, arg_27)), (arg_28) => (new Msg(0, arg_28)))];
        }
        case 17: {
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, false, model.AnalysisFormComponent, analysis_1, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_OfFunc_result(new Msg(10, analysis_1))];
        }
        case 18: {
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, false, model.AnalysisFormComponent, void 0, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_none()];
        }
        case 19: {
            const matchValue_8 = model.StudyGuid;
            if (matchValue_8 == null) {
                return [model, Toast_errorToast("No study selected")];
            }
            else {
                return [model, Cmd_OfFunc_result(new Msg(11, matchValue_8))];
            }
        }
        case 20: {
            const activeStudy_1 = tryFind((study_2) => defaultArg(map((activeGuid) => (study_2.studyId === activeGuid), model.StudyGuid), false), model.Studies);
            const userRoles = defaultArg(map((user) => user.roles, model.MaybeCurrentUser), empty());
            const matchValue_9 = [model.PatientInstitution, model.S3Context, activeStudy_1];
            if (matchValue_9[2] != null) {
                if (matchValue_9[0] == null) {
                    return [model, Toast_errorToast("No patient institution found.")];
                }
                else if (matchValue_9[1] == null) {
                    const patInst_1 = matchValue_9[0];
                    const study_4 = matchValue_9[2];
                    const patternInput_4 = initWithDisplay(analysis_2, new PatientInfo(model.PatientGuid, patInst_1), study_4, patInst_1, userRoles);
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, patternInput_4[0], model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_map((arg_36) => (new Msg(4, arg_36)), patternInput_4[1])];
                }
                else {
                    const context_1 = matchValue_9[1];
                    const patInst = matchValue_9[0];
                    const study_3 = matchValue_9[2];
                    const patternInput_3 = initWithDisplay(analysis_2, new PatientInfo(model.PatientGuid, patInst), study_3, patInst, userRoles);
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, patternInput_3[0], model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_batch(ofArray([Cmd_map((arg_30) => (new Msg(4, arg_30)), patternInput_3[1]), Cmd_OfFunc_result(new Msg(4, new MsgAnalysis(0, new InternalMsg_1(1, (tupledArg = [model.Credentials, context_1], new InboundMsg_3(2, tupledArg[0], tupledArg[1]))))))]))];
                }
            }
            else {
                return [model, Toast_errorToast("No active study found.")];
            }
        }
        case 21: {
            const activeStudy_2 = tryFind((study_5) => defaultArg(map((activeGuid_1) => (study_5.studyId === activeGuid_1), model.StudyGuid), false), model.Studies);
            const userRoles_1 = defaultArg(map((user_1) => user_1.roles, model.MaybeCurrentUser), empty());
            const matchValue_10 = [model.PatientInstitution, model.S3Context, activeStudy_2];
            if (matchValue_10[2] != null) {
                if (matchValue_10[0] == null) {
                    return [model, Toast_errorToast("No patient institution found.")];
                }
                else if (matchValue_10[1] == null) {
                    const patInst_3 = matchValue_10[0];
                    const study_7 = matchValue_10[2];
                    const patternInput_6 = initWithCreate(new PatientInfo(model.PatientGuid, patInst_3), study_7, patInst_3, userRoles_1);
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, patternInput_6[0], model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_map((arg_43) => (new Msg(4, arg_43)), patternInput_6[1])];
                }
                else {
                    const context_2 = matchValue_10[1];
                    const patInst_2 = matchValue_10[0];
                    const study_6 = matchValue_10[2];
                    const patternInput_5 = initWithCreate(new PatientInfo(model.PatientGuid, patInst_2), study_6, patInst_2, userRoles_1);
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, patternInput_5[0], model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_batch(ofArray([Cmd_map((arg_37) => (new Msg(4, arg_37)), patternInput_5[1]), Cmd_OfFunc_result(new Msg(4, new MsgAnalysis(0, new InternalMsg_1(1, (tupledArg_1 = [model.Credentials, context_2], new InboundMsg_3(2, tupledArg_1[0], tupledArg_1[1]))))))]))];
                }
            }
            else {
                return [model, Toast_errorToast("No active study found.")];
            }
        }
        case 22: {
            const matchValue_11 = model.VisualizationComponent;
            if (matchValue_11 == null) {
                return [model, Toast_errorToast("Visualization does not exist")];
            }
            else {
                const patternInput_7 = updateVisualization(token, msg_47, matchValue_11);
                return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, patternInput_7[0], model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_map((arg_44) => (new Msg(12, arg_44)), patternInput_7[1])];
            }
        }
        case 23: {
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, void 0, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_none()];
        }
        case 24: {
            let pattern_matching_result_5, analysis_3, institution, patient;
            if (model.SelectedPatient != null) {
                if (model.PatientInstitution != null) {
                    if (model.SelectedAnalysis != null) {
                        pattern_matching_result_5 = 0;
                        analysis_3 = model.SelectedAnalysis;
                        institution = model.PatientInstitution;
                        patient = model.SelectedPatient;
                    }
                    else {
                        pattern_matching_result_5 = 1;
                    }
                }
                else {
                    pattern_matching_result_5 = 1;
                }
            }
            else {
                pattern_matching_result_5 = 1;
            }
            switch (pattern_matching_result_5) {
                case 0: {
                    const patternInput_8 = init_2(new CustomReportInitArgs(selectedStudy_1, patient, institution, analysis_3, customReportData.geometryData, customReportData.growthData, customReportData.maybeCurrentStudyAcquisitionDate, customReportData.maybePreviousStudyAcquisitionDate));
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, patternInput_8[0], model.CustomReportModel, model.Credentials, model.S3Context), Cmd_map((arg_46) => (new Msg(13, wrapLocalMsg_1(arg_46))), patternInput_8[1])];
                }
                case 1: {
                    return [model, Toast_errorToast("Unable to create custom report")];
                }
            }
        }
        case 25: {
            const matchValue_12 = [model.CustomReportComponent, model.S3Context, model.CustomReportModel];
            if (matchValue_12[0] == null) {
                return [model, Cmd_none()];
            }
            else if (matchValue_12[1] == null) {
                return [model, Toast_errorToast("S3 context does not exist")];
            }
            else if (matchValue_12[2] == null) {
                return [model, Cmd_none()];
            }
            else {
                const customReportComponent = matchValue_12[0];
                const customReportModel = matchValue_12[2];
                const s3Context = matchValue_12[1];
                const patternInput_9 = update_1(new CustomReportUpdateArgs(customReportModel, new FileManagementUpdateArgs(token, model.Credentials, s3Context), defaultArg(map((user_2) => user_2.roles, model.MaybeCurrentUser), empty())), msg_49, customReportComponent);
                return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, patternInput_9[0], model.CustomReportModel, model.Credentials, model.S3Context), Cmd_map((arg_47) => (new Msg(13, arg_47)), patternInput_9[1])];
            }
        }
        case 26: {
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, data, model.Credentials, model.S3Context), Cmd_none()];
        }
        case 27: {
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, void 0, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_none()];
        }
        case 28: {
            if (defaultArg(map((user_3) => isAllowed(new Permission(18))(user_3.roles), model.MaybeCurrentUser), false)) {
                return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, CustomReportViewModel_empty_244AC511(studyId_4), model.Credentials, model.S3Context), Cmd_OfAsyncWith_either((x_4) => {
                    Cmd_OfAsync_start(x_4);
                }, securedApi(token).getCustomReport, studyId_4, (arg_49) => (new Msg(15, arg_49)), (arg_50) => (new Msg(0, arg_50)))];
            }
            else {
                return [model, Cmd_none()];
            }
        }
        case 29: {
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, data_1, model.Credentials, model.S3Context), Cmd_none()];
        }
        case 30: {
            return [model, Cmd_none()];
        }
        case 31: {
            return [model, Toast_errorToast(ErrorMessage_get_describe()(err))];
        }
        case 32: {
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, map((crm) => (new CustomReportViewModel(crm.studyId, crm.maybeConclusion, crm.maybeLastReportPath, crm.maybeLastReportDate, CustomReportStatus_isNowOutdated_73E1A31F(crm.maybeLastReportOutdated)(status))), model.CustomReportModel), model.Credentials, model.S3Context), Cmd_none()];
        }
        case 33: {
            const model_2 = new Model(userInfo, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context);
            if (model_2.StudyGuid != null) {
                return [model_2, Cmd_OfFunc_result(new Msg(26, model_2.StudyGuid))];
            }
            else {
                return [model_2, Cmd_none()];
            }
        }
        case 34: {
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, cognitoCredentials, s3Context_1), Cmd_batch(toList(delay_1(() => {
                let tupledArg_2;
                return append((model.CoreFormComponent != null) ? singleton_1(Cmd_OfFunc_result(new Msg(1, new MsgCore(0, new InternalMsg(1, (tupledArg_2 = [cognitoCredentials, s3Context_1], new InboundMsg_1(1, tupledArg_2[0], tupledArg_2[1]))))))) : empty_1(), delay_1(() => {
                    let tupledArg_3;
                    return append((model.AnalysisFormComponent != null) ? singleton_1(Cmd_OfFunc_result(new Msg(4, new MsgAnalysis(0, new InternalMsg_1(1, (tupledArg_3 = [cognitoCredentials, s3Context_1], new InboundMsg_3(2, tupledArg_3[0], tupledArg_3[1]))))))) : empty_1(), delay_1(() => {
                        let tupledArg_4;
                        return (model.VisualizationComponent != null) ? singleton_1(Cmd_OfFunc_result(new Msg(12, wrapInboundMsg((tupledArg_4 = [cognitoCredentials, s3Context_1], new InboundMsg_2(0, tupledArg_4[0], tupledArg_4[1])))))) : empty_1();
                    }));
                }));
            })))];
        }
        case 35: {
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, pid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, true, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_OfAsyncWith_either((x_5) => {
                Cmd_OfAsync_start(x_5);
            }, securedApi(token).getPatientStudies, model.PatientGuid, (arg_65) => (new Msg(18, arg_65)), (arg_66) => (new Msg(0, arg_66)))];
        }
        case 36: {
            const model_3 = new Model(model.MaybeCurrentUser, studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, false, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context);
            let pattern_matching_result_6, sid_1, sid_2, patient_1;
            if (model_3.StudyGuid != null) {
                if (model_3.CoreFormComponent == null) {
                    pattern_matching_result_6 = 1;
                    sid_2 = model_3.StudyGuid;
                }
                else {
                    pattern_matching_result_6 = 0;
                    sid_1 = model_3.StudyGuid;
                }
            }
            else if (model_3.CoreFormComponent != null) {
                if (model_3.CoreFormComponent.Mode.tag === 1) {
                    pattern_matching_result_6 = 2;
                }
                else if (model_3.SelectedPatient != null) {
                    pattern_matching_result_6 = 3;
                    patient_1 = model_3.SelectedPatient;
                }
                else {
                    pattern_matching_result_6 = 4;
                }
            }
            else if (model_3.SelectedPatient != null) {
                pattern_matching_result_6 = 3;
                patient_1 = model_3.SelectedPatient;
            }
            else {
                pattern_matching_result_6 = 4;
            }
            switch (pattern_matching_result_6) {
                case 0: {
                    const updatedStudy = tryFind((study_8) => (study_8.studyId === sid_1), studies);
                    if (updatedStudy == null) {
                        return [new Model(model_3.MaybeCurrentUser, studies, model_3.Patients, model_3.Institutions, model_3.PatientGuid, model_3.SelectedPatient, model_3.PatientInstitution, model_3.StudyGuid, model_3.CoreFormComponent, model_3.isStudiesLoading, model_3.isAnalysisLoading, model_3.AnalysisFormComponent, model_3.SelectedAnalysis, model_3.VisualizationComponent, model_3.CustomReportComponent, model_3.CustomReportModel, model_3.Credentials, model_3.S3Context), Cmd_none()];
                    }
                    else {
                        const study_9 = updatedStudy;
                        const maybeAnalysisCommand = (model_3.AnalysisFormComponent != null) ? singleton(Cmd_OfFunc_result(new Msg(4, new MsgAnalysis(0, new InternalMsg_1(1, new InboundMsg_3(1, study_9)))))) : empty();
                        return [model_3, Cmd_batch(toList(delay_1(() => append(singleton_1(Cmd_OfFunc_result(new Msg(1, new MsgCore(0, new InternalMsg(1, new InboundMsg_1(0, study_9)))))), delay_1(() => append(singleton_1(Cmd_OfFunc_result(new Msg(1, wrapLocalMsg(new LocalMsg(2, wrapInboundMsg(new InboundMsg(1, study_9.status))))))), delay_1(() => maybeAnalysisCommand)))))))];
                    }
                }
                case 1: {
                    return [model_3, Cmd_OfFunc_result(new Msg(26, sid_2))];
                }
                case 2: {
                    return [model_3, Cmd_none()];
                }
                case 3: {
                    return [new Model(model_3.MaybeCurrentUser, studies, model_3.Patients, model_3.Institutions, model_3.PatientGuid, model_3.SelectedPatient, model_3.PatientInstitution, model_3.StudyGuid, model_3.CoreFormComponent, model_3.isStudiesLoading, model_3.isAnalysisLoading, model_3.AnalysisFormComponent, model_3.SelectedAnalysis, model_3.VisualizationComponent, model_3.CustomReportComponent, model_3.CustomReportModel, model_3.Credentials, model_3.S3Context), Cmd_OfFunc_result(new Msg(21, patient_1.id))];
                }
                case 4: {
                    return [model_3, Cmd_none()];
                }
            }
        }
        case 37: {
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, false, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Toast_errorToast(ErrorMessage_get_describe()(e))];
        }
        case 38: {
            const matchValue_13 = [model, tryFind((study_10) => (study_10.studyId === sid_3), model.Studies)];
            let pattern_matching_result_7, _userInfo, patientInst, study_11;
            if (matchValue_13[0].MaybeCurrentUser != null) {
                if (matchValue_13[0].PatientInstitution != null) {
                    if (matchValue_13[1] != null) {
                        pattern_matching_result_7 = 0;
                        _userInfo = matchValue_13[0].MaybeCurrentUser;
                        patientInst = matchValue_13[0].PatientInstitution;
                        study_11 = matchValue_13[1];
                    }
                    else {
                        pattern_matching_result_7 = 1;
                    }
                }
                else {
                    pattern_matching_result_7 = 1;
                }
            }
            else {
                pattern_matching_result_7 = 1;
            }
            switch (pattern_matching_result_7) {
                case 0: {
                    return [model, Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(27, study_11, patientInst)), Cmd_OfFunc_result(new Msg(7, sid_3))]))];
                }
                case 1: {
                    return [model, Cmd_none()];
                }
            }
        }
        case 39: {
            const matchValue_14 = [model.S3Context, model.MaybeCurrentUser];
            let pattern_matching_result_8, context_3, role, role_1;
            if (matchValue_14[0] == null) {
                if (matchValue_14[1] != null) {
                    if (!isEmpty(matchValue_14[1].roles)) {
                        if (isEmpty(tail(matchValue_14[1].roles))) {
                            pattern_matching_result_8 = 1;
                            role_1 = head(matchValue_14[1].roles);
                        }
                        else {
                            pattern_matching_result_8 = 2;
                        }
                    }
                    else {
                        pattern_matching_result_8 = 2;
                    }
                }
                else {
                    pattern_matching_result_8 = 2;
                }
            }
            else if (matchValue_14[1] != null) {
                if (!isEmpty(matchValue_14[1].roles)) {
                    if (isEmpty(tail(matchValue_14[1].roles))) {
                        pattern_matching_result_8 = 0;
                        context_3 = matchValue_14[0];
                        role = head(matchValue_14[1].roles);
                    }
                    else {
                        pattern_matching_result_8 = 2;
                    }
                }
                else {
                    pattern_matching_result_8 = 2;
                }
            }
            else {
                pattern_matching_result_8 = 2;
            }
            switch (pattern_matching_result_8) {
                case 0: {
                    const patternInput_10 = initWithDisplay_1(model.CoreFormComponent, study_12, patientInstitution_1, role);
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, patternInput_10[0], model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_batch(ofArray([Cmd_map((arg_79) => (new Msg(1, arg_79)), patternInput_10[1]), Cmd_OfFunc_result(new Msg(1, new MsgCore(0, new InternalMsg(1, (tupledArg_5 = [model.Credentials, context_3], new InboundMsg_1(1, tupledArg_5[0], tupledArg_5[1])))))), Cmd_OfFunc_result(new Msg(14, study_12.studyId))]))];
                }
                case 1: {
                    const patternInput_11 = initWithDisplay_1(model.CoreFormComponent, study_12, patientInstitution_1, role_1);
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, patternInput_11[0], model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_batch(ofArray([Cmd_map((arg_85) => (new Msg(1, arg_85)), patternInput_11[1]), Cmd_OfFunc_result(new Msg(14, study_12.studyId))]))];
                }
                case 2: {
                    return [model, Toast_errorToast("No user role found.")];
                }
            }
        }
        case 40: {
            const matchValue_15 = [model.PatientInstitution, model.S3Context, model.MaybeCurrentUser];
            let pattern_matching_result_9, context_4, patInst_4, role_2, patInst_5, role_3;
            if (matchValue_15[0] == null) {
                if (matchValue_15[2] != null) {
                    if (!isEmpty(matchValue_15[2].roles)) {
                        if (isEmpty(tail(matchValue_15[2].roles))) {
                            pattern_matching_result_9 = 2;
                        }
                        else {
                            pattern_matching_result_9 = 3;
                        }
                    }
                    else {
                        pattern_matching_result_9 = 3;
                    }
                }
                else {
                    pattern_matching_result_9 = 3;
                }
            }
            else if (matchValue_15[1] == null) {
                if (matchValue_15[2] != null) {
                    if (!isEmpty(matchValue_15[2].roles)) {
                        if (isEmpty(tail(matchValue_15[2].roles))) {
                            pattern_matching_result_9 = 1;
                            patInst_5 = matchValue_15[0];
                            role_3 = head(matchValue_15[2].roles);
                        }
                        else {
                            pattern_matching_result_9 = 3;
                        }
                    }
                    else {
                        pattern_matching_result_9 = 3;
                    }
                }
                else {
                    pattern_matching_result_9 = 3;
                }
            }
            else if (matchValue_15[2] != null) {
                if (!isEmpty(matchValue_15[2].roles)) {
                    if (isEmpty(tail(matchValue_15[2].roles))) {
                        pattern_matching_result_9 = 0;
                        context_4 = matchValue_15[1];
                        patInst_4 = matchValue_15[0];
                        role_2 = head(matchValue_15[2].roles);
                    }
                    else {
                        pattern_matching_result_9 = 3;
                    }
                }
                else {
                    pattern_matching_result_9 = 3;
                }
            }
            else {
                pattern_matching_result_9 = 3;
            }
            switch (pattern_matching_result_9) {
                case 0: {
                    const patternInput_12 = initWithCreate_1(new PatientInfo_1(patientId, patInst_4), model.Studies, role_2);
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, void 0, patternInput_12[0], model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_batch(ofArray([Navigation_modifyUrl(Urls_pageHash(new Urls_Page(6, new Urls_PatientPage(1, patientId)))), Cmd_map((arg_86) => (new Msg(1, arg_86)), patternInput_12[1]), Cmd_OfFunc_result(new Msg(1, new MsgCore(0, new InternalMsg(1, (tupledArg_6 = [model.Credentials, context_4], new InboundMsg_1(1, tupledArg_6[0], tupledArg_6[1]))))))]))];
                }
                case 1: {
                    const patternInput_13 = initWithCreate_1(new PatientInfo_1(patientId, patInst_5), model.Studies, role_3);
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, void 0, patternInput_13[0], model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_batch(ofArray([Navigation_modifyUrl(Urls_pageHash(new Urls_Page(6, new Urls_PatientPage(1, patientId)))), Cmd_map((arg_92) => (new Msg(1, arg_92)), patternInput_13[1])]))];
                }
                case 2: {
                    return [model, Toast_errorToast("No patient institution found.")];
                }
                case 3: {
                    return [model, Toast_errorToast("No user role found.")];
                }
            }
        }
        case 41: {
            return [model, Cmd_OfFunc_result(new Msg(28, model.PatientGuid))];
        }
        case 42: {
            const model_4 = new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, toSelect, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context);
            return [model_4, Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(26, toSelect)), Cmd_OfFunc_result(new Msg(21, model_4.PatientGuid))]))];
        }
        case 43: {
            let pattern_matching_result_10;
            if (model.CoreFormComponent != null) {
                if (model.CoreFormComponent.Mode.tag === 1) {
                    pattern_matching_result_10 = 0;
                }
                else if (model.CoreFormComponent.Mode.tag === 2) {
                    pattern_matching_result_10 = 0;
                }
                else if (model.AnalysisFormComponent != null) {
                    if (model.AnalysisFormComponent.Mode.tag === 1) {
                        pattern_matching_result_10 = 0;
                    }
                    else if (model.AnalysisFormComponent.Mode.tag === 2) {
                        pattern_matching_result_10 = 0;
                    }
                    else {
                        pattern_matching_result_10 = 1;
                    }
                }
                else {
                    pattern_matching_result_10 = 1;
                }
            }
            else if (model.AnalysisFormComponent != null) {
                if (model.AnalysisFormComponent.Mode.tag === 1) {
                    pattern_matching_result_10 = 0;
                }
                else if (model.AnalysisFormComponent.Mode.tag === 2) {
                    pattern_matching_result_10 = 0;
                }
                else {
                    pattern_matching_result_10 = 1;
                }
            }
            else {
                pattern_matching_result_10 = 1;
            }
            switch (pattern_matching_result_10) {
                case 0: {
                    return [model, Toast_errorToast("Study modification is in progress. Please complete or cancel to navigate away.")];
                }
                case 1: {
                    return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, toSelect_1.studyId, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_batch(singleton(Navigation_newUrl(Urls_pageHash(new Urls_Page(6, new Urls_PatientPage(2, [toSelect_1.patientId, toSelect_1.studyId]))))))];
                }
            }
        }
        case 44: {
            const studyPatient = tryFind((patient_2) => (patient_2.id === toSelect_2), model.Patients);
            const model_5 = new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, toSelect_2, studyPatient, bind((patient_3) => tryFind((inst) => (inst.id === patient_3.institutionId), model.Institutions), studyPatient), model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context);
            const matchValue_16 = [model_5.StudyGuid, model_5.CoreFormComponent];
            if (matchValue_16[0] != null) {
                if (matchValue_16[1] == null) {
                    const studyId_5 = matchValue_16[0];
                    return [model_5, Cmd_OfFunc_result(new Msg(26, studyId_5))];
                }
                else {
                    return [model_5, Cmd_none()];
                }
            }
            else {
                return [new Model(model_5.MaybeCurrentUser, model_5.Studies, model_5.Patients, model_5.Institutions, model_5.PatientGuid, model_5.SelectedPatient, model_5.PatientInstitution, model_5.StudyGuid, void 0, model_5.isStudiesLoading, model_5.isAnalysisLoading, model_5.AnalysisFormComponent, model_5.SelectedAnalysis, model_5.VisualizationComponent, model_5.CustomReportComponent, model_5.CustomReportModel, model_5.Credentials, model_5.S3Context), Cmd_none()];
            }
        }
        case 45: {
            return [new Model(model.MaybeCurrentUser, empty(), model.Patients, model.Institutions, pid_1, void 0, void 0, void 0, void 0, false, false, void 0, void 0, void 0, void 0, void 0, model.Credentials, model.S3Context), Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(17, pid_1)), Cmd_OfFunc_result(new Msg(21, pid_1))]))];
        }
        case 46: {
            return [new Model(model.MaybeCurrentUser, model.Studies, patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_OfFunc_result(new Msg(21, model.PatientGuid))];
        }
        case 47: {
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, model.isAnalysisLoading, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Cmd_OfFunc_result(new Msg(21, model.PatientGuid))];
        }
        case 48: {
            return [model, Navigation_newUrl(Urls_pageHash(new Urls_Page(6, new Urls_PatientPage(0))))];
        }
        case 49: {
            return [new Model(model.MaybeCurrentUser, model.Studies, model.Patients, model.Institutions, model.PatientGuid, model.SelectedPatient, model.PatientInstitution, model.StudyGuid, model.CoreFormComponent, model.isStudiesLoading, false, model.AnalysisFormComponent, model.SelectedAnalysis, model.VisualizationComponent, model.CustomReportComponent, model.CustomReportModel, model.Credentials, model.S3Context), Toast_errorToast(ErrorMessage_get_describe()(e_1))];
        }
        case 50: {
            return [model, Toast_errorToast(ErrorMessage_get_describe()(e_2))];
        }
        case 51: {
            toConsole(printf("Got updated study notification"));
            return [model, Cmd_OfFunc_result(new Msg(17, model.PatientGuid))];
        }
        case 52: {
            return [model, Cmd_none()];
        }
    }
}

