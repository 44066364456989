import { DefaultActions_create, DefaultActions_UpdateArgs$3, DefaultActions_runUpdate, ChildMsg_ofMsg, DefaultActions_ChildModel$2, Child$1, ChildAbsentStrategy$1, Child_absentChildCheck } from "../../Common/Terminate.js";
import { FileComponentSpecs, MaximumFiles } from "../Shared.js";
import { update as update_2, init as init_1 } from "../FileUploadSelectors/DragDropFileSelector/DragDropState.js";
import { Msg, Model } from "./FileParentTypes.js";
import { Cmd_batch, Cmd_none, Cmd_map } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Return_1505, ResultComputationExpression_ResultBuilder__Bind_764BA1D3, ResultComputationExpression_ResultBuilder__Delay_1505, ResultComputationExpression_ResultBuilder__Run_FCFD9EF } from "../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library.3.7.20/Choice.js";
import { Model_getNumberOfAssociatedFiles_Z379152CB, InboundMsg as InboundMsg_2, FileHandling, FileManagementUpdateArgs, Model_tryTerminate_Z379152CB } from "../FileManagementTypes.js";
import { update as update_3, init as init_3, tryTerminate as tryTerminate_1 } from "../FileUploadSelectors/AutoZipSelector/AutoZipState.js";
import { some } from "../../fable_modules/fable-library.3.7.20/Option.js";
import { Cmd_OfFunc_result } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { wrapInboundMsg } from "../../Common/InboundOutbound.js";
import { AutoZipFileSelectorUpdateArgs, InboundMsg } from "../FileUploadSelectors/AutoZipSelector/AutoZipTypes.js";
import { DragDropFileSelectorUpdateArgs, InboundMsg as InboundMsg_1 } from "../FileUploadSelectors/DragDropFileSelector/DragDropTypes.js";
import { length, ofArray } from "../../fable_modules/fable-library.3.7.20/List.js";
import { Toastr_message, Toastr_title, Toastr_position, Toastr_timeout, Toastr_info } from "../../fable_modules/Elmish.Toastr.2.1.0/Elmish.Toastr.fs.js";
import { printf, toText } from "../../fable_modules/fable-library.3.7.20/String.js";
import { init as init_2, update as update_1 } from "../FileManagementState.js";
import { S3Context } from "../../Common/AwsCommon.js";
import { Toast_errorToast } from "../../Common/General.js";
import { Terminate_TerminateError_get_describe } from "../../RAWMap.Models/ErrorMessage.js";
import { FileOperationContext } from "../../RAWMap.Models/Api.js";

export const childAbsentStrategy = new ChildAbsentStrategy$1(0, (model) => Child_absentChildCheck(model.fileUpload.child));

export const fileSpecs = new FileComponentSpecs(void 0, "UploadTest/", new MaximumFiles(0));

export function init() {
    const patternInput = init_1(fileSpecs);
    return [new Model(new DefaultActions_ChildModel$2(new Child$1(1), childAbsentStrategy, "file upload component"), patternInput[0], new DefaultActions_ChildModel$2(new Child$1(1), childAbsentStrategy, "auto zip component")), Cmd_map((arg) => (new Msg(5, arg)), patternInput[1])];
}

export function tryTerminate(model) {
    return ResultComputationExpression_ResultBuilder__Run_FCFD9EF(ResultComputationExpression_result, ResultComputationExpression_ResultBuilder__Delay_1505(ResultComputationExpression_result, () => {
        let matchValue;
        return ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, (matchValue = model.fileUpload.child, (matchValue.tag === 1) ? (new FSharpResult$2(0, () => {
        })) : ((matchValue.tag === 2) ? (new FSharpResult$2(0, () => {
        })) : Model_tryTerminate_Z379152CB(matchValue.fields[0]))), (_arg) => {
            let matchValue_1;
            return ResultComputationExpression_ResultBuilder__Bind_764BA1D3(ResultComputationExpression_result, (matchValue_1 = model.autoZip.child, (matchValue_1.tag === 1) ? (new FSharpResult$2(0, () => {
            })) : ((matchValue_1.tag === 2) ? (new FSharpResult$2(0, () => {
            })) : tryTerminate_1(matchValue_1.fields[0]))), (_arg_1) => ResultComputationExpression_ResultBuilder__Return_1505(ResultComputationExpression_result, () => {
                _arg();
                _arg_1();
            }));
        });
    }));
}

export function update(args, msg, model) {
    let arg_4, inputRecord_1, inputRecord_2;
    const matchValue = [msg, model];
    let pattern_matching_result, ex, model_1, msg_10, fileModel, msg_22, msg_23, selected, selected_1, msg_32;
    if (matchValue[0].tag === 1) {
        pattern_matching_result = 1;
    }
    else if (matchValue[0].tag === 2) {
        if (matchValue[0].fields[0].elmishChainedMsg.tag === 1) {
            if (matchValue[0].fields[0].elmishChainedMsg.fields[0].tag === 3) {
                pattern_matching_result = 2;
            }
            else {
                pattern_matching_result = 3;
                msg_10 = matchValue[0].fields[0];
            }
        }
        else {
            pattern_matching_result = 3;
            msg_10 = matchValue[0].fields[0];
        }
    }
    else if (matchValue[0].tag === 4) {
        pattern_matching_result = 4;
    }
    else if (matchValue[0].tag === 3) {
        pattern_matching_result = 5;
    }
    else if (matchValue[0].tag === 5) {
        if (matchValue[0].fields[0].tag === 1) {
            pattern_matching_result = 8;
            selected = matchValue[0].fields[0].fields[0].fields[0];
        }
        else if (matchValue[1].fileUpload.child.tag === 0) {
            pattern_matching_result = 6;
            fileModel = matchValue[1].fileUpload.child.fields[0];
            msg_22 = matchValue[0].fields[0].fields[0];
        }
        else {
            pattern_matching_result = 7;
            msg_23 = matchValue[0].fields[0].fields[0];
        }
    }
    else if (matchValue[0].tag === 6) {
        if (matchValue[0].fields[0].elmishChainedMsg.tag === 1) {
            if (matchValue[0].fields[0].elmishChainedMsg.fields[0].tag === 2) {
                pattern_matching_result = 9;
                selected_1 = matchValue[0].fields[0].elmishChainedMsg.fields[0].fields[0];
            }
            else {
                pattern_matching_result = 10;
                msg_32 = matchValue[0].fields[0];
            }
        }
        else {
            pattern_matching_result = 10;
            msg_32 = matchValue[0].fields[0];
        }
    }
    else {
        pattern_matching_result = 0;
        ex = matchValue[0].fields[0];
        model_1 = matchValue[1];
    }
    switch (pattern_matching_result) {
        case 0: {
            console.error(some(ex));
            return [model_1, Cmd_none()];
        }
        case 1: {
            return [model, Cmd_batch(ofArray([Cmd_OfFunc_result(new Msg(6, ChildMsg_ofMsg(childAbsentStrategy, wrapInboundMsg(new InboundMsg(0))))), Cmd_OfFunc_result(new Msg(5, wrapInboundMsg(new InboundMsg_1(0))))]))];
        }
        case 2: {
            return [model, Cmd_OfFunc_result(new Msg(1))];
        }
        case 3: {
            const patternInput = DefaultActions_runUpdate(new DefaultActions_UpdateArgs$3((msg_11, model_2) => {
                let inputRecord;
                if (msg_11.tag === 1) {
                    return [model_2, Toastr_info(Toastr_timeout(2000, Toastr_position("toast-bottom-right", Toastr_title("Info", Toastr_message(toText(printf("Outbound of %O"))(msg_11.fields[0]))))))];
                }
                else {
                    return update_1(new FileManagementUpdateArgs(args.Token, args.Credentials, (inputRecord = args.Context, new S3Context(inputRecord.S3Config, inputRecord.S3Client, inputRecord.S3Expiry, args.Context.ActiveBucket))), msg_11.fields[0], model_2);
                }
            }, model), model.fileUpload, msg_10);
            return [new Model(patternInput[0], model.dragDrop, model.autoZip), Cmd_map((arg_3) => (new Msg(2, arg_3)), patternInput[1])];
        }
        case 4: {
            const matchValue_1 = tryTerminate(model);
            if (matchValue_1.tag === 1) {
                return [model, Toast_errorToast((arg_4 = Terminate_TerminateError_get_describe()(matchValue_1.fields[0]), toText(printf("Termination failed: %O"))(arg_4)))];
            }
            else {
                matchValue_1.fields[0]();
                return [new Model((inputRecord_1 = model.fileUpload, new DefaultActions_ChildModel$2(new Child$1(2), inputRecord_1.childAbsentStrategy, inputRecord_1.childComponentName)), model.dragDrop, (inputRecord_2 = model.autoZip, new DefaultActions_ChildModel$2(new Child$1(2), inputRecord_2.childAbsentStrategy, inputRecord_2.childComponentName))), Cmd_none()];
            }
        }
        case 5: {
            const createdUpload = init_2(fileSpecs, new FileOperationContext(6), new FileHandling(0));
            const createdAutoZip = init_3(fileSpecs);
            const patternInput_1 = DefaultActions_create(createdUpload[0], createdUpload[1], model.fileUpload);
            const patternInput_2 = DefaultActions_create(createdAutoZip[0], createdAutoZip[1], model.autoZip);
            return [new Model(patternInput_1[0], model.dragDrop, patternInput_2[0]), Cmd_batch(ofArray([Cmd_map((arg_5) => (new Msg(2, arg_5)), patternInput_1[1]), Cmd_map((arg_6) => (new Msg(6, arg_6)), patternInput_2[1]), Cmd_OfFunc_result(new Msg(2, ChildMsg_ofMsg(childAbsentStrategy, wrapInboundMsg(new InboundMsg_2(0)))))]))];
        }
        case 6: {
            const patternInput_3 = update_2(new DragDropFileSelectorUpdateArgs(args.Token, Model_getNumberOfAssociatedFiles_Z379152CB(fileModel) + length(fileModel.UploadSelection), args.Credentials, args.Context), msg_22, model.dragDrop);
            return [new Model(model.fileUpload, patternInput_3[0], model.autoZip), Cmd_map((arg_8) => (new Msg(5, arg_8)), patternInput_3[1])];
        }
        case 7: {
            const patternInput_4 = update_2(new DragDropFileSelectorUpdateArgs(args.Token, 0, args.Credentials, args.Context), msg_23, model.dragDrop);
            return [new Model(model.fileUpload, patternInput_4[0], model.autoZip), Cmd_map((arg_9) => (new Msg(5, arg_9)), patternInput_4[1])];
        }
        case 8: {
            return [model, Cmd_OfFunc_result(new Msg(2, ChildMsg_ofMsg(childAbsentStrategy, wrapInboundMsg(new InboundMsg_2(1, selected)))))];
        }
        case 9: {
            return [model, Cmd_OfFunc_result(new Msg(2, ChildMsg_ofMsg(childAbsentStrategy, wrapInboundMsg(new InboundMsg_2(1, selected_1)))))];
        }
        case 10: {
            const patternInput_5 = DefaultActions_runUpdate(new DefaultActions_UpdateArgs$3((msg_33, zipModel) => {
                const matchValue_2 = [msg_33, model];
                if (matchValue_2[0].tag === 1) {
                    return [zipModel, Toastr_info(Toastr_timeout(2000, Toastr_position("toast-bottom-right", Toastr_title("Info", Toastr_message(toText(printf("Outbound of %O"))(matchValue_2[0].fields[0]))))))];
                }
                else if (matchValue_2[1].fileUpload.child.tag === 0) {
                    return update_3(new AutoZipFileSelectorUpdateArgs(args.Token, Model_getNumberOfAssociatedFiles_Z379152CB(matchValue_2[1].fileUpload.child.fields[0]) + length(matchValue_2[1].fileUpload.child.fields[0].UploadSelection), args.Credentials, args.Context), matchValue_2[0].fields[0], zipModel);
                }
                else {
                    return update_3(new AutoZipFileSelectorUpdateArgs(args.Token, 0, args.Credentials, args.Context), matchValue_2[0].fields[0], zipModel);
                }
            }, model), model.autoZip, msg_32);
            return [new Model(model.fileUpload, model.dragDrop, patternInput_5[0]), Cmd_map((arg_15) => (new Msg(6, arg_15)), patternInput_5[1])];
        }
    }
}

