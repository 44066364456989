function logDebug(module, isDebugging, msg) {
    if (isDebugging) {
        console.log(`${module} :: ${msg}`);
    }
}

function debugCallback(isDebugging, callback) {
    if (isDebugging) {
        callback();
    }
}

function mkFontInfo(sizeInPixels, family) {
    const fontInfo = {
        sizeInPixels,
        font: `${sizeInPixels}px ${family}`
    };
    return fontInfo;
}

function isInitialized(x) {
    return typeof x !== 'undefined';
}

/**
 * Ignore the passed value.
 * @param _
 * @return {void}
 */
function ignore(_) {}

/**
 * Gets the name of the discriminated union.
 * @param du
 * @return {*}
 */
function getDUName(du) {
    return du.name ?? du;
}

const displayMapTypes = Object.freeze({
    ANATOMY: "Anatomy",
    LOCAL_EXPANSION_GROWTH: "LocalExpansionGrowth",
    DIAMETRIC_GROWTH: "DiametricGrowth",
    ILT: "Ilt",
    SECTIONAL_RAW: "SectionalRaw",
    CONTINUOUS_RAW: "ContinuousRaw",
    STRAIN: "Strain"
});

const constants = Object.freeze({
    staticLabelCanvasId: "staticLabelCanvas",
    worldLabelCanvasId: "worldLabelCanvas",
    anatomyScalarBarCanvasId: "anatomyScalarBarCanvas",
    planeResolution: 21,
    screenshotSize: {
        width: 600,
        height: 400
    },
    splitScreenshotSize: {
        width: 350,
        height: 400,
    },
    splitScreenshotPadding: 2,
    meshOpacity: 0.5,
    dataArrayNames: {
        analysisPipelineVersion: "AP_Version",
        acquisitionDate: "Acquisition_Date",
        renalColor: "Colour",
        renalOpacity: "Opacity",
        anatomyColor: "Anatomy_Colour"
    }
});

export const allCanvases = [
    "measurement_" + constants.staticLabelCanvasId,
    "measurement_" + constants.worldLabelCanvasId,
    "static_" + constants.staticLabelCanvasId,
    "static_" + constants.worldLabelCanvasId,
    constants.anatomyScalarBarCanvasId,
    `pickedMapValue_${constants.staticLabelCanvasId}`,
    `pickedMapValue_${constants.worldLabelCanvasId}`];

export { logDebug, debugCallback, mkFontInfo, isInitialized, constants, ignore, getDUName, displayMapTypes };
