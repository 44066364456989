import { Union, Record } from "../../fable_modules/fable-library.3.7.20/Types.js";
import { StudyViewModel$reflection } from "../../RAWMap.Models/View/Study.js";
import { PatientViewModel$reflection } from "../../RAWMap.Models/View/Patient.js";
import { InstitutionViewModel$reflection } from "../../RAWMap.Models/View/Institution.js";
import { AnalysisViewModel$reflection } from "../../RAWMap.Models/View/Analysis.js";
import { class_type, union_type, record_type, option_type, list_type, tuple_type, string_type } from "../../fable_modules/fable-library.3.7.20/Reflection.js";
import { CustomReportStatus$reflection, CustomReportViewModel$reflection } from "../../RAWMap.Models/View/CustomReport.js";
import { FileManagementUpdateArgs$reflection } from "../../FileManagement/FileManagementTypes.js";
import { UserRole$reflection } from "../../RAWMap.Models/Security.js";
import { FSharpResult$2, FSharpChoice$2 } from "../../fable_modules/fable-library.3.7.20/Choice.js";
import { OutboundMsg$reflection as OutboundMsg$reflection_1, InboundMsg$reflection as InboundMsg$reflection_1, LocalMsg$reflection as LocalMsg$reflection_1, ModelScreenshot$reflection } from "../../Visualization/Screenshot/ScreenshotTypes.js";
import { OutboundMsg$reflection as OutboundMsg$reflection_2, InboundMsg$reflection as InboundMsg$reflection_2, LocalMsg$reflection as LocalMsg$reflection_2, AdditionalDataModel$reflection } from "./AdditionalData/AdditionalDataTypes.js";
import { PromptType$reflection, OutboundMsg$reflection as OutboundMsg$reflection_3, InboundMsg$reflection as InboundMsg$reflection_3, LocalMsg$reflection as LocalMsg$reflection_3, ModelReportPrompt$reflection } from "./ReportPrompt/ReportPromptTypes.js";
import { ChildAbsentStrategy$1$reflection, DefaultActions_ChildModel$2$reflection } from "../../Common/Terminate.js";
import { ErrorMessage$reflection } from "../../RAWMap.Models/ErrorMessage.js";
import { Msg$3$reflection } from "../../Common/InboundOutbound.js";
import { ChainedChildMsg$2$reflection } from "../../Common/ElmishDebugger/ChainedChildMsg.js";
import { Csv_CsvRowMulti$reflection } from "../../RAWMap.Models/Common.js";

export class CustomReportInitArgs extends Record {
    constructor(selectedStudy, selectedPatient, patientInstitution, studyAnalysis, geometryData, growthData, maybeCurrentStudyAcquisitionDate, maybePreviousStudyAcquisitionDate) {
        super();
        this.selectedStudy = selectedStudy;
        this.selectedPatient = selectedPatient;
        this.patientInstitution = patientInstitution;
        this.studyAnalysis = studyAnalysis;
        this.geometryData = geometryData;
        this.growthData = growthData;
        this.maybeCurrentStudyAcquisitionDate = maybeCurrentStudyAcquisitionDate;
        this.maybePreviousStudyAcquisitionDate = maybePreviousStudyAcquisitionDate;
    }
}

export function CustomReportInitArgs$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.Types.CustomReportInitArgs", [], CustomReportInitArgs, () => [["selectedStudy", StudyViewModel$reflection()], ["selectedPatient", PatientViewModel$reflection()], ["patientInstitution", InstitutionViewModel$reflection()], ["studyAnalysis", AnalysisViewModel$reflection()], ["geometryData", option_type(list_type(tuple_type(string_type, string_type)))], ["growthData", option_type(list_type(tuple_type(string_type, string_type)))], ["maybeCurrentStudyAcquisitionDate", option_type(string_type)], ["maybePreviousStudyAcquisitionDate", option_type(string_type)]]);
}

export class CustomReportUpdateArgs extends Record {
    constructor(customReport, fileArgs, userRoles) {
        super();
        this.customReport = customReport;
        this.fileArgs = fileArgs;
        this.userRoles = userRoles;
    }
}

export function CustomReportUpdateArgs$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.Types.CustomReportUpdateArgs", [], CustomReportUpdateArgs, () => [["customReport", CustomReportViewModel$reflection()], ["fileArgs", FileManagementUpdateArgs$reflection()], ["userRoles", list_type(UserRole$reflection())]]);
}

export class Operation extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GeneratingReportPdf"];
    }
}

export function Operation$reflection() {
    return union_type("RAWMap.Client.Study.CustomReport.Types.Operation", [], Operation, () => [[]]);
}

export function Operation_viewDisplay_4DAF4246(operation) {
    return "Generating Report";
}

export class Mode extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Review", "RunningOperation"];
    }
}

export function Mode$reflection() {
    return union_type("RAWMap.Client.Study.CustomReport.Types.Mode", [], Mode, () => [[], [["Item", Operation$reflection()]]]);
}

export function ModeModule_$007CDisableButtons$007CEnableButtons$007C(mode) {
    if (mode.tag === 0) {
        return new FSharpChoice$2(1, void 0);
    }
    else {
        return new FSharpChoice$2(0, Operation_viewDisplay_4DAF4246(mode.fields[0]));
    }
}

export function ModeModule_$007CSpinner$007CNoSpinner$007C(mode) {
    if (mode.tag === 0) {
        return new FSharpChoice$2(1, void 0);
    }
    else {
        return new FSharpChoice$2(0, Operation_viewDisplay_4DAF4246(mode.fields[0]));
    }
}

export class ModelCustomReport extends Record {
    constructor(SelectedStudy, SelectedPatient, PatientInstitution, StudyAnalysis, MaybePreviousStudy, GeometryData, GrowthData, MaybeCurrentStudyAcquisitionDate, MaybePreviousStudyAcquisitionDate, ScreenshotModel, AdditionalDataModel, Mode, PromptComponent) {
        super();
        this.SelectedStudy = SelectedStudy;
        this.SelectedPatient = SelectedPatient;
        this.PatientInstitution = PatientInstitution;
        this.StudyAnalysis = StudyAnalysis;
        this.MaybePreviousStudy = MaybePreviousStudy;
        this.GeometryData = GeometryData;
        this.GrowthData = GrowthData;
        this.MaybeCurrentStudyAcquisitionDate = MaybeCurrentStudyAcquisitionDate;
        this.MaybePreviousStudyAcquisitionDate = MaybePreviousStudyAcquisitionDate;
        this.ScreenshotModel = ScreenshotModel;
        this.AdditionalDataModel = AdditionalDataModel;
        this.Mode = Mode;
        this.PromptComponent = PromptComponent;
    }
}

export function ModelCustomReport$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.Types.ModelCustomReport", [], ModelCustomReport, () => [["SelectedStudy", StudyViewModel$reflection()], ["SelectedPatient", PatientViewModel$reflection()], ["PatientInstitution", InstitutionViewModel$reflection()], ["StudyAnalysis", AnalysisViewModel$reflection()], ["MaybePreviousStudy", option_type(StudyViewModel$reflection())], ["GeometryData", option_type(list_type(tuple_type(string_type, string_type)))], ["GrowthData", option_type(list_type(tuple_type(string_type, string_type)))], ["MaybeCurrentStudyAcquisitionDate", option_type(string_type)], ["MaybePreviousStudyAcquisitionDate", option_type(string_type)], ["ScreenshotModel", ModelScreenshot$reflection()], ["AdditionalDataModel", AdditionalDataModel$reflection()], ["Mode", Mode$reflection()], ["PromptComponent", DefaultActions_ChildModel$2$reflection(ModelReportPrompt$reflection(), ModelCustomReport$reflection())]]);
}

export class LocalMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExnErrorMsg", "TryGetPreviousStudy", "TryGetPreviousStudyResult", "ScreenshotMsg", "AdditionalDataMsg", "ReportPromptMsg", "TerminatePromptComponent", "CreatePromptComponent", "DownloadMeasurementCsv", "GetMeasurementCsvRows"];
    }
}

export function LocalMsg$reflection() {
    return union_type("RAWMap.Client.Study.CustomReport.Types.LocalMsg", [], LocalMsg, () => [[["Item", class_type("System.Exception")]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(StudyViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(StudyViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], [["Item", Msg$3$reflection(LocalMsg$reflection_1(), InboundMsg$reflection_1(), OutboundMsg$reflection_1())]], [["Item", Msg$3$reflection(LocalMsg$reflection_2(), InboundMsg$reflection_2(), OutboundMsg$reflection_2())]], [["Item", ChainedChildMsg$2$reflection(Msg$3$reflection(LocalMsg$reflection_3(), InboundMsg$reflection_3(), OutboundMsg$reflection_3()), ChildAbsentStrategy$1$reflection(ModelCustomReport$reflection()))]], [], [["Item", PromptType$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Csv_CsvRowMulti$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(Csv_CsvRowMulti$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]], []]);
}

export class InboundMsg {
    constructor() {
    }
}

export function InboundMsg$reflection() {
    return class_type("RAWMap.Client.Study.CustomReport.Types.InboundMsg", void 0, InboundMsg);
}

export class OutboundMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UpdateCustomReportStatus", "UpdateCustomReport", "CancelCustomReport"];
    }
}

export function OutboundMsg$reflection() {
    return union_type("RAWMap.Client.Study.CustomReport.Types.OutboundMsg", [], OutboundMsg, () => [[["Item", CustomReportStatus$reflection()]], [["Item", CustomReportViewModel$reflection()]], []]);
}

