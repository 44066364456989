import { Record } from "../../../../fable_modules/fable-library.3.7.20/Types.js";
import { record_type, option_type, list_type, tuple_type, string_type } from "../../../../fable_modules/fable-library.3.7.20/Reflection.js";
import { map2, flatten, defaultArg, map } from "../../../../fable_modules/fable-library.3.7.20/Option.js";
import { isEmpty, ofArray, singleton, map as map_1 } from "../../../../fable_modules/fable-library.3.7.20/List.js";
import { replace } from "../../../../fable_modules/fable-library.3.7.20/RegExp.js";
import { Csv_CsvRowMulti } from "../../../../RAWMap.Models/Common.js";
import { AsyncResultComputationExpression_asyncResult, AsyncResultComputationExpression_AsyncResultBuilder__Return_1505, AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B } from "../../../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { Pdf_Spacing_topMargin, Pdf_Table_Basic_make, Pdf_Table_ofCsvRows } from "../ReportPdfHelpers.js";
import { TimeDelta_calculateTimeDelta } from "./SharedSection.js";

export class Args extends Record {
    constructor(data, maybeCurrentStudyAcquisitionDate, maybePreviousStudyAcquisitionDate) {
        super();
        this.data = data;
        this.maybeCurrentStudyAcquisitionDate = maybeCurrentStudyAcquisitionDate;
        this.maybePreviousStudyAcquisitionDate = maybePreviousStudyAcquisitionDate;
    }
}

export function Args$reflection() {
    return record_type("RAWMap.Client.Study.CustomReport.ReportPdf.Sections.AdditionalDataSection.Args", [], Args, () => [["data", option_type(list_type(tuple_type(string_type, string_type)))], ["maybeCurrentStudyAcquisitionDate", option_type(string_type)], ["maybePreviousStudyAcquisitionDate", option_type(string_type)]]);
}

function makeCsvRows(args) {
    return map((list) => map_1((tupledArg) => (new Csv_CsvRowMulti(tupledArg[0], singleton(replace(tupledArg[1], "\\\\", "")))), list), args.data);
}

export function create(title, doc, args) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => {
        let doc_2, matchValue, c, multiRows_1, p, patternInput_1, doc_4, multiRows_2, patternInput_2;
        const multiRows = makeCsvRows(args);
        const patternInput = Pdf_Table_ofCsvRows(ofArray(["Current Study Acquisition Date", "Previous Study Acquisition Date", "Time Delta"]), singleton(new Csv_CsvRowMulti(defaultArg(args.maybeCurrentStudyAcquisitionDate, ""), ofArray([defaultArg(args.maybePreviousStudyAcquisitionDate, ""), defaultArg(map((tupledArg) => (`${tupledArg[0]} years, ${tupledArg[1]} months`), flatten(map2(TimeDelta_calculateTimeDelta, args.maybeCurrentStudyAcquisitionDate, args.maybePreviousStudyAcquisitionDate))), "N/A")]))));
        return AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, (doc_2 = doc, (matchValue = [multiRows, args.maybeCurrentStudyAcquisitionDate, args.maybePreviousStudyAcquisitionDate], (matchValue[0] != null) ? (isEmpty(matchValue[0]) ? doc_2 : ((matchValue[1] != null) ? ((matchValue[2] != null) ? ((c = matchValue[1], (multiRows_1 = matchValue[0], (p = matchValue[2], (patternInput_1 = Pdf_Table_ofCsvRows(ofArray(["Name", "Value"]), multiRows_1), (doc_4 = Pdf_Table_Basic_make("Acquisition Time", patternInput[0], patternInput[1], Pdf_Spacing_topMargin, doc_2.addPage()), Pdf_Table_Basic_make(title, patternInput_1[0], patternInput_1[1], Pdf_Spacing_topMargin + defaultArg(flatten(map((table) => table.finalY, doc_4.lastAutoTable)), 0), doc_4))))))) : ((multiRows_2 = matchValue[0], (patternInput_2 = Pdf_Table_ofCsvRows(ofArray(["Name", "Value"]), multiRows_2), Pdf_Table_Basic_make(title, patternInput_2[0], patternInput_2[1], Pdf_Spacing_topMargin, doc_2.addPage()))))) : ((multiRows_2 = matchValue[0], (patternInput_2 = Pdf_Table_ofCsvRows(ofArray(["Name", "Value"]), multiRows_2), Pdf_Table_Basic_make(title, patternInput_2[0], patternInput_2[1], Pdf_Spacing_topMargin, doc_2.addPage())))))) : doc_2)));
    });
}

